import { CreditCardDto } from 'api/cases/interfaces/CreditCardDto';
import { useFieldPermission } from 'auth';
import { DynamicField } from 'components/Dynamic';

export const CASE_CREDIT_CARD_PERMISSION = 'CaseCreditCardTransactionDto';

const creditCardFields: DynamicField<CreditCardDto>[] = [
  {
    label: 'Last 4 digits of Credit Card Number',
    name: 'fourNumbers',
  },
  {
    label: 'Card Type',
    name: 'cardType',
  },
  {
    label: 'Authorization Code',
    name: 'cardAuthCode',
  },
  {
    label: 'Settlement ID',
    name: 'settlementId',
  },
  {
    label: 'Charged Amount',
    name: 'cardChargeAmount',
  },
  {
    label: 'Billing Zip Code',
    name: 'zipCode',
  },
  {
    label: 'Authorization Date',
    name: 'authorizationDate',
  },
  {
    label: 'Settlement Date',
    name: 'settlementDate',
  },
];

interface Props {
  isEditMode: boolean;
}

export function useCreditCardConfig({ isEditMode }: Props) {
  return {
    creditCardFields: useFieldPermission({
      fields: creditCardFields,
      table: CASE_CREDIT_CARD_PERMISSION,
      isEditMode,
    }),
  };
}
