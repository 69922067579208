import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { DynamicForm } from 'components/Dynamic';
import DataTable from 'components/DataTable/DataTable';
import {
  CaseEventData,
  CaseEventDetails,
  EventsStatusHistory,
} from 'features/Cases/interfaces/CaseEventDetails';
import { Box, Typography } from '@mui/material';
import FormDivider from 'components/Form/FormDivider';
import { DynamicExpandableFormActions } from 'components/Dynamic/DynamicExpandableFormActions';
import { useUpdateCaseEventDetails } from 'features/Cases/queries/manageCaseQueries';
import { useEventStatusConfig, eventStatusColumns, eventDetailValidationSchema } from './config';
import { useCaseContext } from 'features/Cases/CaseProvider';
import { yupResolver } from '@hookform/resolvers/yup';
import { getDirtyFieldValues } from 'utils/getDirtyFieldValues';
import { combineDateAndTime } from 'utils/convertDate';

const splitCaseEventData = (data: CaseEventData): [CaseEventDetails, EventsStatusHistory[]] => {
  const { eventStatusHistory, ...caseEventDetails } = data;
  return [caseEventDetails, eventStatusHistory];
};

interface Props {
  isEditMode: boolean;
  data: CaseEventData;
  caseUuid: string;
  setEditMode: (value: boolean) => void;
  setIsEditingSection: (value: boolean) => void;
}

const EventDetailsEditor = ({
  isEditMode,
  data,
  caseUuid,
  setEditMode,
  setIsEditingSection,
}: Props) => {
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('createdAt');
  const [eventDetails, eventStatusHistory] = splitCaseEventData(data);

  const {
    mutateAsync: updateEventDetailsMutateAsync,
    isError: isUpdateEventDetailsError,
    reset: resetUpdateEventDetailsError,
    errorMessage: errorMessageEventDetails,
  } = useUpdateCaseEventDetails(caseUuid);

  const hookForm = useForm<CaseEventDetails>({
    defaultValues: eventDetails,
    resolver: yupResolver(eventDetailValidationSchema),
  });

  const { resetAll } = useCaseContext();
  const { isDirty, dirtyFields } = hookForm.formState;

  const handleSubmit = async (data: CaseEventDetails) => {
    if (isDirty) {
      const dirtyData = getDirtyFieldValues<CaseEventDetails>(data, dirtyFields);

      const updatedData = {
        ...dirtyData,
        promisedTimeArrival:
          dirtyData.promisedTimeArrival &&
          combineDateAndTime(data.dayPromisedTimeToArrival, dirtyData.promisedTimeArrival),
        promisedTimeArrivalPlusFiftyPercent:
          dirtyData.promisedTimeArrivalPlusFiftyPercent &&
          combineDateAndTime(
            data.dayPromisedTimeArrivalPlusFiftyPercent,
            dirtyData.promisedTimeArrivalPlusFiftyPercent,
          ),
      };

      await updateEventDetailsMutateAsync(updatedData);
      setEditMode(false);
      setIsEditingSection(false);
      resetAll();
    }
  };

  const handleDiscard = () => {
    setEditMode(false);
    setIsEditingSection(false);
    hookForm.reset();
    resetUpdateEventDetailsError();
  };

  const createSortHandler = (_: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const { eventDetailFields } = useEventStatusConfig(isEditMode);

  return (
    <>
      <form>
        <DynamicForm
          hookForm={hookForm}
          isEditMode={isEditMode}
          fields={eventDetailFields}
          colspan={2.4}
        />
        <FormDivider label="Event Status" sx={{ mb: 3, mt: 2 }} />

        <Box sx={{ pb: 4, mx: -4 }}>
          <DataTable
            columns={eventStatusColumns}
            rows={eventStatusHistory}
            sortOptions={{ order, orderBy, createSortHandler, hasSort: true }}
          />
        </Box>

        <DynamicExpandableFormActions
          isEditMode={isEditMode}
          save={{
            onSubmit: hookForm.handleSubmit(handleSubmit),
            disabled: !isDirty,
          }}
          discard={{
            onSubmit: handleDiscard,
          }}
        />
      </form>

      {/* TODO: Adjust error message with design */}
      {isUpdateEventDetailsError && (
        <Typography color="error">
          An error has occurred while updating Event Details:
          {errorMessageEventDetails}
        </Typography>
      )}
    </>
  );
};

export default EventDetailsEditor;
