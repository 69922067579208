import { useFeaturePermission } from 'auth';
import { ControlledFormTableColumn } from 'components/ControlledForm/ControlledFormTable/ControlledFormTable';
import * as Yup from 'yup';
import { DynamicFieldType } from 'components/Dynamic';
import { useTableFieldPermission } from 'auth/permissions/useTableFieldPermission';
import { CaseServiceProvidedData, TroubleCodeData, TroubleCodes } from './ServicesProvidedQuery';
import { ServiceProvidedDto } from 'api/servicesProvided/interfaces';
import { DataTableColumn } from 'components/DataTable';
import { TroubleCodeDto } from 'api/cases/interfaces/TroubleCodeDto';
import { combineArraysAndRemoveDuplicates } from 'utils';
import { PriceType } from 'api/cases/enums/PriceType';

interface TroubleCodeColumnsProps {
  isEditMode: boolean;
  problemAndPacesetterCodes: TroubleCodeData[];
}

interface ServicesProvidedColumnsProps {
  isEditMode: boolean;
  serviceList: ServiceProvidedDto[];
  servicesProvided: CaseServiceProvidedData[];
  totalCost: number;
  nationalUser: boolean;
}

export const CASE_SERVICES_PROVIDED_PERMISSION = 'ServiceProvidedDto';
export const CASE_TROUBLE_CODE_PERMISSION = 'TroubleCodeDto';

export function useServiceProvidedConfig() {
  return {
    permissions: useFeaturePermission({ table: CASE_SERVICES_PROVIDED_PERMISSION }),
  };
}

const PriceTypeOptions = Object.values(PriceType);

export const servicesProvidedValidationSchema = Yup.object().shape({
  servicesProvided: Yup.array().of(
    Yup.object().shape({
      unitCount: Yup.number()
        .typeError('Unit count must be a number')
        .required('Unit count is required'),
      unitOfMeasure: Yup.string().required('Unit of measure is required'),
      code: Yup.string().required('Service is required'),
      priceType: Yup.string().oneOf(
        PriceTypeOptions,
        `Price Type must be ${PriceTypeOptions.join(' or ')}`,
      ),
      cost: Yup.number()
        .transform((value, original) => (original.toString().trim() === '' ? 0 : value))
        .nullable(true)
        .typeError('Vendor cost must be a number'),
    }),
  ),
});

export const getServicesProvidedColumns = (
  serviceList: ServiceProvidedDto[],
  totalCost: number,
  servicesProvided: CaseServiceProvidedData[],
  nationalUser: boolean,
): ControlledFormTableColumn<CaseServiceProvidedData>[] => {
  // Workaround for services that are not in RAP core, need to have thouse as an option to be selected
  const completeServiceListOptions = combineArraysAndRemoveDuplicates(
    serviceList,
    servicesProvided,
    'code',
  );

  const serviceProvidedColumns: ControlledFormTableColumn<CaseServiceProvidedData>[] = [
    {
      label: 'Unit Count',
      id: 'unitCount',
      width: 150,
      fieldType: DynamicFieldType.TEXT,
    },
    {
      label: 'Unit of Measure',
      id: 'unitOfMeasure',
      width: 150,
      fieldType: DynamicFieldType.TEXT,
      isReadOnly: true,
      accessor: (row) => (row.code === 'T6' ? 'ML' : 'EA'),
    },
    {
      label: 'Service',
      id: 'code',
      width: 235,
      fieldType: DynamicFieldType.SELECT,
      options:
        completeServiceListOptions?.map((s) => ({
          value: s.code,
          label: `${s.code}${s.name ? ' - ' + s.name : ''}`,
        })) ?? [],
      Footer: () => 'Total',
    },
    {
      label: 'Vendor Cost',
      id: 'cost',
      width: '32%',
      fieldType: DynamicFieldType.CURRENCY,
      startAdornment: '$',
      Footer: () =>
        totalCost.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          style: 'currency',
          currency: 'USD',
        }),
    },
    ...(nationalUser
      ? [
          {
            label: 'Price Type',
            id: 'priceType' as keyof CaseServiceProvidedData,
            fieldType: DynamicFieldType.SELECT,
            options: PriceTypeOptions.map((value) => ({
              value,
              label: value,
            })),
          },
        ]
      : []),
  ];

  return serviceProvidedColumns;
};

export function useServicesProvidedColumnsConfig({
  isEditMode,
  serviceList,
  servicesProvided,
  totalCost,
  nationalUser,
}: ServicesProvidedColumnsProps) {
  return {
    servicesProvidedColumns: useTableFieldPermission({
      columns: getServicesProvidedColumns(serviceList, totalCost, servicesProvided, nationalUser),
      table: CASE_SERVICES_PROVIDED_PERMISSION,
      isEditMode,
    }),
  };
}

export function useTroubleCodeConfig() {
  return {
    permissions: useFeaturePermission({ table: CASE_TROUBLE_CODE_PERMISSION }),
  };
}

export function useTroubleCodeColumnsConfig({
  isEditMode,
  problemAndPacesetterCodes,
}: TroubleCodeColumnsProps) {
  return {
    troubleCodesColumns: useTableFieldPermission({
      columns: getTroubleColumns(problemAndPacesetterCodes),
      table: CASE_TROUBLE_CODE_PERMISSION,
      isEditMode,
    }),
  };
}

export const getTroubleColumns = (
  problemAndPacesetterCodes: TroubleCodeData[],
): ControlledFormTableColumn<TroubleCodeData>[] => {
  // Workaround for trouble codes that are not in TroubleCodes enum
  const troubleCodes = Object.entries(TroubleCodes).map(([key]) => ({ label: key, value: key }));
  const addedTroubleCodes = problemAndPacesetterCodes.map(({ code }) => ({
    label: code,
    value: code,
  }));
  const troubleCodeOptions = combineArraysAndRemoveDuplicates(
    troubleCodes,
    addedTroubleCodes,
    'value',
  );

  const troubleCodesColumns: ControlledFormTableColumn<TroubleCodeData>[] = [
    {
      label: 'Code',
      id: 'code',
      fieldType: DynamicFieldType.SELECT,
      width: 186,
      options: troubleCodeOptions,
    },
    {
      label: 'Description',
      id: 'description',
      fieldType: DynamicFieldType.TEXT,
      isReadOnly: true,
    },
  ];

  return troubleCodesColumns;
};

export const codeColumnDef: DataTableColumn<TroubleCodeDto>[] = [
  {
    label: 'Code',
    id: 'code',
    accessor: (c) => c.code,
    width: 186,
  },
  {
    label: 'Description',
    id: 'description',
    accessor: (c) => c.description,
  },
];
