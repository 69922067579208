import { BaseAPI } from 'api/auth';
import { ENV_BACKOFFICE_BASE_API_URL } from 'lib/envConfig';
import { TroubleCodeDto } from './interfaces/TroubleCodeDto';
import { CreditCardTransactionDto } from 'api/creditCards/interfaces/CreditCardTransactionDto';
import { BreakdownLocationDto } from './interfaces/BreakdownLocationDto';
import { CaseGeneralInfoDto } from './interfaces/GeneralInfoDto';
import { TowDestinationDto } from './interfaces/TowDestinationDto';
import { CaseErrorDto } from './interfaces/CaseErrorDto';
import { CaseServiceProvidedDto, TroubleCodeTypes } from './interfaces/ServiceProvidedDto';
import { EventDetailsDto } from './interfaces/EventDetailsDto';
import { BaseCommentDto, CommentDto } from './interfaces/CommentDto';
import { CaseFinancialViewDto, GMClaimDto } from './interfaces/CaseFinancialDto';
import { CreatePageableCRUDApi } from 'api/helper/PageableCRUDApi';
import { CaseRejectDto } from './interfaces/CaseRejectDto';
import { CreditCardReceiptDto } from './interfaces/CreditCardReceipt';
import { RbpCaseStatus } from 'core/enums/RbpCaseStatus';
import { SmsDto } from './interfaces/SmsDto';
import { BillingRequestDto } from 'api/invoices';
import { BulkActionDto } from './interfaces/BulkActionDto';

export const manageCasesApi = CreatePageableCRUDApi<CaseGeneralInfoDto>(
  `${ENV_BACKOFFICE_BASE_API_URL}/v1/cases`,
);

export function fetchCaseCreditCardByUUID(uuid: string) {
  return BaseAPI.get<CreditCardTransactionDto[]>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/cases/${uuid}/credit-cards`,
  );
}

export function fetchCaseCreditCardReceiptByUUID(uuid: string) {
  return BaseAPI.get<CreditCardReceiptDto>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/cases/${uuid}/receipts`,
  );
}

export function fetchBreakdownLocationByUUID(uuid: string) {
  return BaseAPI.get<BreakdownLocationDto>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/cases/${uuid}/breakdown-locations`,
  );
}

export function updateBreakdownLocationByUUID(
  caseUUID: string,
  breakdownLocationUUID: string,
  breakdownLocationData: Partial<BreakdownLocationDto>,
) {
  return BaseAPI.put<BreakdownLocationDto>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/cases/${caseUUID}/breakdown-locations/${breakdownLocationUUID}`,
    breakdownLocationData,
  );
}

export function fetchTowDestinationsByUUID(uuid: string) {
  return BaseAPI.get<TowDestinationDto[]>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/cases/${uuid}/tow-destinations`,
  );
}

export function fetchCallGeneralInfoByUUID(uuid: string) {
  return BaseAPI.get<CaseGeneralInfoDto>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/cases/${uuid}/general-info`,
  );
}

export function updateCallGeneralInfoByUUID(uuid: string, data: Partial<CaseGeneralInfoDto>) {
  return BaseAPI.put<CaseGeneralInfoDto>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/cases/${uuid}/general-info`,
    data,
  );
}

export function fetchCallAddonsByUUID(uuid: string) {
  return BaseAPI.get<{ [key: string]: string }>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/cases/${uuid}/addons`,
  );
}

export function updateCallAddonsByUUID(uuid: string, data: Partial<{ [key: string]: string }>) {
  return BaseAPI.put<{ [key: string]: string }>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/cases/${uuid}/addons`,
    data,
  );
}

export function fetchCaseErrorsByUUID(caseId: string) {
  return BaseAPI.get<CaseErrorDto[]>(`${ENV_BACKOFFICE_BASE_API_URL}/v1/cases/${caseId}/errors`);
}

export function updateCaseErrorByUUID(caseId: string, data: Partial<CaseErrorDto>[]) {
  return BaseAPI.patch<CaseErrorDto>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/cases/${caseId}/errors`,
    data,
  );
}

export function fetchCaseServicesProvided(caseId: string) {
  return BaseAPI.get<CaseServiceProvidedDto[]>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/cases/${caseId}/services-provided`,
  );
}

export function updateServicesProvided(caseId: string, payload: Partial<CaseServiceProvidedDto>[]) {
  return BaseAPI.post(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/cases/${caseId}/services-provided/batch`,
    payload,
  );
}

export function fetchTroubleCodes(
  caseId: string,
  types: TroubleCodeTypes[] = ['PROBLEM', 'RESOLUTION', 'PACESETTER'],
) {
  const params = new URLSearchParams();
  types.forEach((type) => params.append('troubleCodeTypes', type));
  return BaseAPI.get<TroubleCodeDto[]>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/cases/${caseId}/trouble-codes?${params}`,
  );
}

export function updateTroubleCodeByUUID(
  caseId: string,
  troubleCodeUUID: string,
  troubleCodeData: Partial<TroubleCodeDto>,
) {
  return BaseAPI.put<TroubleCodeDto>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/cases/${caseId}/trouble-codes/${troubleCodeUUID}`,
    troubleCodeData,
  );
}

export function fetchCaseEventDetailsByUUID(caseId: string) {
  return BaseAPI.get<EventDetailsDto>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/cases/${caseId}/event-details`,
  );
}

export function updateCaseEventDetails(uuid: string, data: Partial<EventDetailsDto>) {
  return BaseAPI.put<EventDetailsDto>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/cases/${uuid}/event-details`,
    data,
  );
}

export function fetchCaseCommentsByUUID(caseId: string) {
  return BaseAPI.get<CommentDto[]>(`${ENV_BACKOFFICE_BASE_API_URL}/v1/cases/${caseId}/comments`);
}

export function fetchCaseSmsByUUID(caseId: string) {
  return BaseAPI.get<SmsDto[]>(`${ENV_BACKOFFICE_BASE_API_URL}/v1/cases/${caseId}/sms`);
}

export function updateTowDestinationByUUID(
  caseUUID: string,
  towDestinationUUID: string | undefined,
  towDestinationData: Partial<TowDestinationDto>,
) {
  return BaseAPI.put<TowDestinationDto>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/cases/${caseUUID}/tow-destinations/${towDestinationUUID}`,
    towDestinationData,
  );
}

export function deleteTowDestinationByUUID(
  caseUUID: string,
  towDestinationUUID: string | undefined,
) {
  return BaseAPI.delete<TowDestinationDto>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/cases/${caseUUID}/tow-destinations/${towDestinationUUID}`,
  );
}

export function addTowDestinationByUUID(
  caseUUID: string,
  towDestinationData: Partial<TowDestinationDto>,
) {
  return BaseAPI.post<TowDestinationDto>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/cases/${caseUUID}/tow-destinations`,
    towDestinationData,
  );
}

export function fetchCaseFinancials(uuid: string) {
  return BaseAPI.get<CaseFinancialViewDto>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/cases/${uuid}/financials`,
  );
}

export function fetchCaseRejects(uuid: string) {
  return BaseAPI.get<CaseRejectDto>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v2/cases/${uuid}/claim-rejects`,
  );
}

export function fetchCaseClaims(uuid: string) {
  return BaseAPI.get<GMClaimDto[]>(`${ENV_BACKOFFICE_BASE_API_URL}/v1/cases/${uuid}/claims`);
}

export function addUserComment(caseUuid: string, comment: BaseCommentDto) {
  return BaseAPI.post<BaseCommentDto>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/cases/${caseUuid}/comments`,
    comment,
  );
}

export function addCaseAdjustment(caseUuid: string, body: any) {
  return BaseAPI.post(`${ENV_BACKOFFICE_BASE_API_URL}/v1/cases/${caseUuid}/adjustment`, body);
}

export function updateCaseStatus(uuid: string, rbpCaseStatus: RbpCaseStatus) {
  return BaseAPI.patch<RbpCaseStatus>(`${ENV_BACKOFFICE_BASE_API_URL}/v1/cases/${uuid}/status`, {
    rbpCaseStatus,
  });
}

export function updateCallStatus(uuid: string, callStatus: string) {
  return BaseAPI.patch<RbpCaseStatus>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/cases/${uuid}/call-status`,
    {
      callStatus,
    },
  );
}

export function fetchCaseAttachableBillingRequests(uuid: string) {
  return BaseAPI.get<BillingRequestDto[]>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/cases/${uuid}/billing-requests`,
  );
}

export function executeBulkAction(body: BulkActionDto) {
  return BaseAPI.post(`${ENV_BACKOFFICE_BASE_API_URL}/v1/bulk-actions`, body);
}

export function updateCaseClaim(uuid: string, claimUuid: string, clientId: number, body: any) {
  const config = {
    headers: {
      'client-id': clientId,
    },
  };
  return BaseAPI.patch(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/cases/${uuid}/claims-items/${claimUuid}`,
    body,
    config,
  );
}
