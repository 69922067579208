const currentFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export function convertToCurrency(value?: number) {
  if (value === null || value === undefined) {
    return '';
  }

  return currentFormatter.format(value);
}

export function convertToNumber(formattedValue: string): number {
  const numericValue = Number(formattedValue.replace(/[^\d.-]/g, ''));

  if (isNaN(numericValue)) {
    return 0;
  }

  return numericValue;
}
