import { PropsWithChildren } from 'react';
import { CRUDPageableProvider, usePageableContextData } from 'components/CRUDPageable';
import { CRUDPageableReactQuery } from 'hooks/useReactQueryHelpers';
import { CaseGeneralInfoDto } from 'api/cases/interfaces/GeneralInfoDto';
import { manageCasesApi } from 'api/cases/manageCasesApi';
import { CaseData } from './interfaces/CaseData';
import { convertUTCDateToString } from 'utils/convertDate';
import { PageableProviderData } from 'components/CRUDPageable/types';
import { convertToStringLeadZero } from 'utils/convertToStringLeadZero';
import { isEditable } from './helpers';

export const mapDtoToData = (data: CaseGeneralInfoDto): CaseData => ({
  uuid: data.uuid,
  rapCaseId: convertToStringLeadZero(data.rapCaseId, 8),
  clubCode: convertToStringLeadZero(data.clubCode, 3),
  rapProgramCode: data.rapProgramCode,
  programName: data.programName,
  clientName: data.clientName,
  clientId: data.clientId,
  vehicleVin: data.vehicleVin,
  rapCallCreatedDateTime: convertUTCDateToString(data.rapCallCreatedDateTime),
  rapCallClosedDateTime: convertUTCDateToString(data.rapCallClosedDateTime, 'America/New_York'),
  rbpCaseStatus: data.rbpCaseStatus,
  callStatus: data.callStatus,
  caseErrors: data.caseErrors,
  isEditable: isEditable(data.rbpCaseStatus),
  message: !isEditable(data.rbpCaseStatus)
    ? `This case can't be edited when RBP Case Status = ${data.rbpCaseStatus}`
    : '',
  financialItems: data.financialItems,
  bulkActionStatus: data.bulkActionStatus,
  totalAmount: data.totalAmount,
});

export const CaseQuery = CRUDPageableReactQuery<CaseGeneralInfoDto, CaseData>(
  'QUERY_CASES',
  manageCasesApi,
  mapDtoToData,
);

export function useCaseContext() {
  return usePageableContextData<CaseGeneralInfoDto, CaseData>();
}

const initialState: Partial<PageableProviderData> = {
  orderBy: 'rapCallCreatedDateTime',
  order: 'desc',
};

export default function CaseProvider({ children }: PropsWithChildren) {
  return (
    <CRUDPageableProvider queries={CaseQuery} initialState={initialState} reQueryOnMount>
      {children}
    </CRUDPageableProvider>
  );
}
