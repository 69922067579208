import { CaseGeneralInfoData } from './interface/CaseGeneralInfoData';
import { DynamicField, DynamicFieldType } from 'components/Dynamic';
import { PaymentResponsibleValues } from 'api/cases/enums/PaymentResponsible';
import { PhoneTypeValues } from 'api/cases/enums/PhoneType';
import { ProgramData } from 'features/Setup/Programs/ProgramQuery';
import { ServiceDutyValues } from 'api/cases/enums/ServiceDuty';
import { joinValues } from 'utils/joinValues';
import { useFeaturePermission } from 'auth/permissions/useFeaturePermission';
import { useFieldPermission } from 'auth/permissions/useFieldPermission';
import { useMemo } from 'react';
import { channelType } from 'api/cases/enums/ChannelType';
import { LightTooltip } from 'components/Form/FormInputTooltip';
import AppColors from 'AppColors';
import { convertUTCToDateTimeString } from 'utils/convertDate';
import { InputAdornment } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import CallKeyTooltip from './components/CallKeyTooltip';

export const GENERAL_INFO_PERMISSION = 'CaseGeneralInfoDto';

interface Props {
  programData: ProgramData[];
  isEditMode: boolean;
  generalInfoData: CaseGeneralInfoData;
}

export function useGeneralInfoConfig({ programData, isEditMode, generalInfoData }: Props) {
  const hasCallKey = Boolean(generalInfoData.callKey);

  const generalInfoFields = useMemo<DynamicField<CaseGeneralInfoData>[]>(
    () => [
      {
        label: 'RAP Case ID',
        name: 'rapCaseId',
        ...(hasCallKey && {
          endAdornment: <CallKeyTooltip generalInfoData={generalInfoData} />,
        }),
      },
      {
        label: 'RAP Program Code',
        name: 'rapProgramCode',
      },
      {
        label: 'Club Code',
        name: 'clubCode',
      },
      {
        label: 'Club Name',
        name: 'clubName',
      },
      {
        label: 'Client Name - ID',
        name: 'clientName',
      },
      {
        label: 'Program Name - ID',
        name: 'programId',
        type: DynamicFieldType.SELECT,
        select: {
          options: programData
            ? programData
                .filter((item) => item.rapProgramId !== null && item.rapProgramId !== undefined)
                .map((item) => ({
                  label: joinValues([item.programName, item.rapProgramId], ' - '),
                  value: item.rapProgramId?.toString() || '',
                }))
            : [],
        },
      },
      {
        label: 'Agent',
        name: 'agentId',
      },
      {
        label: 'Call Center Phone Number',
        name: 'callCenterPhoneNumber',
        isReadOnly: true,
      },
      {
        label: 'Call Date & Time',
        name: 'rapCallCreatedDateTime',
        isReadOnly: true,
      },
      {
        label: 'Case Channel',
        name: 'channelType',
        type: DynamicFieldType.SELECT,
        select: {
          options: Object.entries(channelType).map(([key, value]) => ({
            value: key,
            label: value,
          })),
        },
      },
      {
        label: 'Payment Responsibility',
        name: 'paymentResponsible',
        type: DynamicFieldType.SELECT,
        select: {
          options: PaymentResponsibleValues.map((item) => ({ label: item, value: item })),
        },
      },
      {
        label: 'Priority Code',
        name: 'servicePriorityCode',
      },
      {
        label: 'UTC Date & Time',
        name: 'rapCallUtcCreatedDateTime',
        endAdornment: (
          <InputAdornment position="end">
            <LightTooltip
              arrow
              placement={'top'}
              title={`${convertUTCToDateTimeString(
                generalInfoData.rapCallUtcCreatedDateTimeOriginal,
                true,
                Intl.DateTimeFormat().resolvedOptions().timeZone,
              )} in your Time Zone`}
            >
              <InfoIcon sx={{ color: AppColors.AAA_LIGHT_BLUE }} />
            </LightTooltip>
          </InputAdornment>
        ),
        isReadOnly: true,
      },
      {
        label: 'Accident',
        name: 'accident',
        type: DynamicFieldType.CHECKBOX,
      },
      {
        label: 'Goodwill',
        name: 'goodwill',
        type: DynamicFieldType.CHECKBOX,
      },
      {
        label: 'Trip Interruption',
        name: 'tripInterruption',
        type: DynamicFieldType.CHECKBOX,
      },
    ],
    [programData],
  );

  const vehicleFields = useMemo<DynamicField<CaseGeneralInfoData>[]>(
    () => [
      {
        label: 'VIN',
        name: 'vehicleVin',
      },
      {
        label: 'Year',
        name: 'vehicleYear',
      },
      {
        label: 'Manufacturer or Make',
        name: 'vehicleMake',
      },
      {
        label: 'Model',
        name: 'vehicleModel',
      },
      {
        label: 'Color',
        name: 'vehicleColor',
      },
      {
        label: 'Odometer',
        name: 'vehicleOdometer',
      },
      {
        label: 'State',
        name: 'vehicleLicenseState',
      },
      {
        label: 'License Plate',
        name: 'vehicleTag',
      },
      {
        label: 'Duty Indicator',
        name: 'serviceDuty',
        type: DynamicFieldType.SELECT,
        select: {
          options: ServiceDutyValues.map((item) => ({ label: item, value: item })),
        },
      },
      {
        label: 'Passengers',
        name: 'serviceNumberOfPassengers',
      },
      {
        label: 'Un-attended',
        name: 'vehicleUnattendedVehicle',
        type: DynamicFieldType.CHECKBOX,
      },
    ],
    [],
  );

  const customerInfoFields = useMemo<DynamicField<CaseGeneralInfoData>[]>(
    () => [
      {
        label: 'Contact First Name',
        name: 'contactFirstName',
      },
      {
        label: 'Contact Last Name',
        name: 'contactLastName',
      },
      {
        label: 'Phone Number',
        name: 'contactPhoneNumber',
      },
      {
        label: 'Phone Extension',
        name: 'contactPhoneExtension',
      },
      {
        label: 'Phone Type',
        name: 'contactPhoneType',
        type: DynamicFieldType.SELECT,
        select: {
          options: PhoneTypeValues.map((item) => ({ label: item, value: item })),
        },
      },
    ],
    [],
  );

  return {
    generalInfoFields: useFieldPermission({
      fields: generalInfoFields,
      table: GENERAL_INFO_PERMISSION,
      isEditMode,
    }),
    vehicleFields: useFieldPermission({
      fields: vehicleFields,
      table: GENERAL_INFO_PERMISSION,
      isEditMode,
    }),
    customerInfoFields: useFieldPermission({
      fields: customerInfoFields,
      table: GENERAL_INFO_PERMISSION,
      isEditMode,
    }),
  };
}

export function useGeneralInfoPermissions() {
  return useFeaturePermission({ table: GENERAL_INFO_PERMISSION });
}
