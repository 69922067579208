import AddonsTable from './AddonsTable';
import FormDivider from 'components/Form/FormDivider';
import { CaseGeneralInfoData } from 'features/Cases/CaseDetails/GeneralInfo/interface/CaseGeneralInfoData';
import { DynamicExpandableFormActions, DynamicForm } from 'components/Dynamic';
import { Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useGeneralInfoConfig } from './config';
import { useGeneralInfoCoreData } from './useGeneralInfoCoreData';
import { useUpdateCaseAddonsByUUID, useUpdateCaseGeneralInfoByUUID } from './GeneralInfoQuery';
import { useCaseContext } from 'features/Cases/CaseProvider';
import { getDirtyFieldValues } from 'utils/getDirtyFieldValues';

export interface Props {
  generalInfoData: CaseGeneralInfoData;
  addonsData: { [key: string]: string };
  refetchGeneralInfo: () => void;
  refetchAddons: () => void;
  isLoadingAddons: boolean;
  isEditMode: boolean;
  setEditMode: (value: boolean) => void;
  setIsEditingSection: (value: boolean) => void;
}

const GeneralInfoEditor: React.FC<Props> = ({
  generalInfoData,
  addonsData,
  refetchGeneralInfo,
  refetchAddons,
  isEditMode,
  setEditMode,
  setIsEditingSection,
  isLoadingAddons,
}) => {
  const {
    mutateAsync: generalInfoMutateAsync,
    isError: isGeneralInfoUpdateError,
    reset: resetGeneralInfoUpdate,
    errorMessage: errorMessageGeneralInfo,
  } = useUpdateCaseGeneralInfoByUUID(generalInfoData.rbpCaseUuid);
  const {
    mutateAsync: addonsMutateAsync,
    isError: isAddonsUpdateError,
    reset: resetAddonsUpdate,
    errorMessage: errorMessageAddons,
  } = useUpdateCaseAddonsByUUID(generalInfoData.rbpCaseUuid);

  const { programData } = useGeneralInfoCoreData();
  const generalInfoHookForm = useForm({ defaultValues: generalInfoData });
  const addonsHookForm = useForm({ defaultValues: addonsData });
  const { resetAll } = useCaseContext();

  const handleDiscard = () => {
    generalInfoHookForm.reset();
    addonsHookForm.reset();
    resetGeneralInfoUpdate();
    resetAddonsUpdate();
    setEditMode(false);
    setIsEditingSection(false);
    refetchGeneralInfo();
    refetchAddons();
  };

  const { isDirty: isGeneralInfoDirty, dirtyFields: generalInfoDirtyFields } =
    generalInfoHookForm.formState;
  const { isDirty: isAddonsDirty } = addonsHookForm.formState;

  const handleSave = () => {
    const onGeneralInfoSubmit = async (data: CaseGeneralInfoData) => {
      if (isGeneralInfoDirty) {
        const dirtyData = getDirtyFieldValues<CaseGeneralInfoData>(data, generalInfoDirtyFields);
        await generalInfoMutateAsync(dirtyData);
        refetchGeneralInfo();
        resetAll();
      }
    };

    const onAddonsSubmit = async (data: { [key: string]: string }) => {
      if (isAddonsDirty) {
        await addonsMutateAsync(data);
        refetchAddons();
        resetAll();
      }
    };

    const generalInfoTask = generalInfoHookForm.handleSubmit(onGeneralInfoSubmit);
    const addonsTask = addonsHookForm.handleSubmit(onAddonsSubmit);

    Promise.all([generalInfoTask(), addonsTask()]).then(() => {
      setEditMode(false);
      setIsEditingSection(false);
    });
  };

  const { customerInfoFields, generalInfoFields, vehicleFields } = useGeneralInfoConfig({
    isEditMode,
    programData,
    generalInfoData,
  });

  return (
    <Stack spacing={0}>
      <DynamicForm
        hookForm={generalInfoHookForm}
        isEditMode={isEditMode}
        fields={generalInfoFields}
      />

      <AddonsTable
        data={addonsData}
        control={addonsHookForm.control}
        isEditMode={isEditMode}
        generalInfoData={generalInfoData}
        refetchAddons={refetchAddons}
        isLoadingAddons={isLoadingAddons}
      />

      {vehicleFields.length > 0 && (
        <>
          <FormDivider label="Vehicle" sx={{ mt: 1, mb: 3 }} />
          <DynamicForm
            hookForm={generalInfoHookForm}
            isEditMode={isEditMode}
            fields={vehicleFields}
            colspan={2.4}
          />
        </>
      )}

      {customerInfoFields.length > 0 && (
        <>
          <FormDivider label="Customer Info" sx={{ my: 3 }} />
          <DynamicForm
            hookForm={generalInfoHookForm}
            isEditMode={isEditMode}
            fields={customerInfoFields}
            colspan={2.4}
          />
        </>
      )}

      <DynamicExpandableFormActions
        isEditMode={isEditMode}
        save={{
          onSubmit: handleSave,
          disabled: !isGeneralInfoDirty && !isAddonsDirty,
        }}
        discard={{
          onSubmit: handleDiscard,
        }}
      />
      {isGeneralInfoUpdateError && (
        <Typography color="error">
          An Error Has Occurred While Updating Case General Information:
          {errorMessageGeneralInfo}
        </Typography>
      )}
      {isAddonsUpdateError && (
        <Typography color="error">
          An Error Has Occurred While Updating Client Specific Fields:
          {errorMessageAddons}
        </Typography>
      )}
    </Stack>
  );
};

export default GeneralInfoEditor;
