import { IconButton, InputAdornment } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AppColors from 'AppColors';
import { LightTooltip } from 'components/Form/FormInputTooltip';
import { CaseGeneralInfoData } from '../interface/CaseGeneralInfoData';

interface CallKeyTooltipProps {
  generalInfoData: CaseGeneralInfoData;
}

const CallKeyTooltip = ({ generalInfoData }: CallKeyTooltipProps) => {
  return (
    <InputAdornment position="end">
      <LightTooltip
        arrow
        placement={'top'}
        title={
          <>
            <span>Call Key: {generalInfoData.callKey}</span>
            <IconButton
              size="small"
              onClick={() => navigator.clipboard.writeText(String(generalInfoData.callKey))}
            >
              <ContentCopyIcon fontSize="small" />
            </IconButton>
          </>
        }
      >
        <IconButton size="small">
          <InfoIcon sx={{ color: AppColors.AAA_LIGHT_BLUE }} />
        </IconButton>
      </LightTooltip>
    </InputAdornment>
  );
};

export default CallKeyTooltip;
