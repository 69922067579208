/**
 * Extracts the city, state, and postal code from an address string.
 * @param {string | undefined} address - The address string to extract information from.
 * @returns {{ city: string; state: string; postalCode: string }} An object containing the city, state, and postal code.
 */
export function extractAddressParts(address: string | undefined): {
  city: string;
  state: string;
  postalCode: string;
} {
  if (!address) return { city: '', state: '', postalCode: '' };

  const parts = address.split(',').map((part) => part.trim());
  return {
    city: parts[0] || '',
    state: parts[1] || '',
    postalCode: parts[2] || '',
  };
}
