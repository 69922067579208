import { useEffect, useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormDivider from 'components/Form/FormDivider';
import {
  useBreakdownLocationConfig,
  useTowDestinationConfig,
  towDestinationValidationSchema,
  useTowDestinationPermissions,
  findCaseErrorByCodeAndResolutionType,
} from './config';
import { DynamicForm } from 'components/Dynamic';
import { TowDestinationRecord } from 'features/Cases/interfaces/CaseViewPanels';
import { ControlledFormTable } from 'components/ControlledForm/ControlledFormTable';
import { Box, Stack, Button, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { DynamicExpandableFormActions } from 'components/Dynamic/DynamicExpandableFormActions';
import DynamicFormDeleteAction from 'components/Dynamic/DynamicFormDeleteAction/DynamicFormDeleteAction';
import { nanoid } from 'nanoid';
import {
  useDeleteTowDestinationByUUID,
  useUpdateTowDestinationByUUID,
  useAddTowDestinationByUUID,
  BreakdownLocationData,
  useUpdateBreakdownLocationByUUID,
} from './BreakdownAndTowQuery';
import { useCaseContext } from 'features/Cases/CaseProvider';
import { getDirtyFieldValues } from 'utils/getDirtyFieldValues';
import { useGetCaseErrorsByUUID } from 'features/Cases/queries';
import { ResolutionTypes } from 'api/cases/interfaces/CaseErrorDto';

export interface Props {
  breakdownLocationData: BreakdownLocationData;
  towDestinationData: TowDestinationRecord[];
  isEditMode: boolean;
  setEditMode: (value: boolean) => void;
  setIsEditingSection: (value: boolean) => void;
  caseUUID: string;
}

interface TowDestinationFormInput {
  towDestination: Partial<TowDestinationRecord>[];
}

const BreakdownAndTowEditor: React.FC<Props> = ({
  breakdownLocationData,
  towDestinationData,
  isEditMode,
  setEditMode,
  setIsEditingSection,
  caseUUID,
}) => {
  const [selectedTowDestinations, setSelectedTowDestinations] = useState<string[]>([]);
  const [deletedTowDestinations, setDeletedTowDestinations] = useState<string[]>([]);
  const [resetTrigger, setResetTrigger] = useState<boolean>(false);
  const { resetAll } = useCaseContext();

  const breakdownLocationHookForm = useForm<BreakdownLocationData>({
    defaultValues: breakdownLocationData,
  });

  const towDestinationHookForm = useForm<TowDestinationFormInput>({
    defaultValues: { towDestination: towDestinationData || [] },
    resolver: yupResolver(towDestinationValidationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { append, remove, fields } = useFieldArray({
    control: towDestinationHookForm.control,
    name: 'towDestination',
  });

  const { towDestination: towDestinationDirtyFields } =
    towDestinationHookForm.formState.dirtyFields;

  const { isDirty: isBreakdownLocationDirty, dirtyFields: breakdownLocationDirtyFields } =
    breakdownLocationHookForm.formState;
  const { isDirty: isTowDestinationDirty } = towDestinationHookForm.formState;

  const {
    isError: isUpdateBreakdownLocationError,
    errorMessage: updateBreakdownLocationErrorMessage,
    mutateAsync: updateBreakdownLocationMutateAsync,
    reset: updateBreakdownLocationReset,
  } = useUpdateBreakdownLocationByUUID(caseUUID);
  const {
    isError: isUpdateTowDestinationError,
    errorMessage: updateTowDestinationErrorMessage,
    mutateAsync: updateTowDestinationMutateAsync,
    reset: updateTowDestinationReset,
  } = useUpdateTowDestinationByUUID(caseUUID);

  const {
    isError: isDeleteTowDestinationError,
    errorMessage: deleteTowDestinationErrorMessage,

    mutateAsync: deleteTowDestinationMutateAsync,
    reset: deleteTowDestinationReset,
  } = useDeleteTowDestinationByUUID(caseUUID);

  const {
    isError: isAddTowDestinationError,
    errorMessage: addTowDestinationErrorMessage,
    mutateAsync: addTowDestinationMutateAsync,
    reset: addTowDestinationReset,
  } = useAddTowDestinationByUUID(caseUUID);

  const { data: caseErrorsData } = useGetCaseErrorsByUUID(caseUUID, true);

  const handleResetSelect = () => {
    setResetTrigger((current) => !current);
  };

  const handleSave = async () => {
    const isTowDestinationValid = await towDestinationHookForm.trigger();

    if (!isTowDestinationValid) {
      return;
    }

    const onBreakdownLocationSubmit = async (data: BreakdownLocationData) => {
      if (isBreakdownLocationDirty) {
        const dirtyData = getDirtyFieldValues<BreakdownLocationData>(
          data,
          breakdownLocationDirtyFields,
        );

        const updatedData = {
          ...dirtyData,
          uuid: data.uuid,
        };
        await updateBreakdownLocationMutateAsync(updatedData);
      }
    };

    const onTowDestinationSubmit = async (data: TowDestinationFormInput) => {
      const createAndUpdate = Promise.all(
        data.towDestination.map(async (towDestination, i) => {
          if (towDestination.uuid?.includes('fakeUuid-')) {
            delete towDestination.uuid;
            await addTowDestinationMutateAsync(towDestination);
          } else {
            if (
              towDestinationDirtyFields &&
              Object.values(towDestinationDirtyFields[i] || {}).some((value) => value === true)
            ) {
              await updateTowDestinationMutateAsync(towDestination);
            }
          }
        }),
      );

      const removeDestinations = Promise.all(
        deletedTowDestinations.map(async (towDestinationUUID) => {
          await deleteTowDestinationMutateAsync(towDestinationUUID);
        }),
      );

      await Promise.all([createAndUpdate, removeDestinations]);
    };

    const breakdownLocationTask = breakdownLocationHookForm.handleSubmit(onBreakdownLocationSubmit);
    const towDestinationTask = towDestinationHookForm.handleSubmit(onTowDestinationSubmit);

    Promise.all([breakdownLocationTask(), towDestinationTask()]).then(() => {
      resetAll();
      setEditMode(false);
      setIsEditingSection(false);
      setSelectedTowDestinations([]);
      setDeletedTowDestinations([]);
    });
  };

  const handleDiscard = () => {
    towDestinationHookForm.reset();
    breakdownLocationHookForm.reset();
    updateBreakdownLocationReset();
    updateTowDestinationReset();
    addTowDestinationReset();
    deleteTowDestinationReset();
    setSelectedTowDestinations([]);
    setDeletedTowDestinations([]);
    setIsEditingSection(false);
    setEditMode(false);
  };

  const onAddTowDestination = () => {
    append({
      uuid: `fakeUuid-${nanoid()}`,
      locationName: '',
      addressType: '',
      computedTowMiles: '0',
      address: '',
      cityStateZip: '',
      fullPhoneNumber: '',
      dealerId: '',
      landmark: '',
    });
  };

  const onDeleteTowDestination = () => {
    const itemsToRemove = selectedTowDestinations.filter(
      (x) => !deletedTowDestinations.includes(x),
    );
    const indexes = itemsToRemove.map((towDestination) => {
      return fields.findIndex((field) => field.uuid === towDestination);
    });
    remove(indexes);
    setDeletedTowDestinations((current) => [...current, ...itemsToRemove]);
    setSelectedTowDestinations([]);
    handleResetSelect();
  };

  useEffect(() => {
    towDestinationHookForm.reset({ towDestination: towDestinationData || [] });
  }, [towDestinationHookForm, towDestinationData]);

  const { breakdownLocationFields } = useBreakdownLocationConfig({ isEditMode });
  const { towDestinationColumns } = useTowDestinationConfig({ isEditMode });
  const { permissions } = useTowDestinationPermissions();

  const hasError65Pending = findCaseErrorByCodeAndResolutionType(
    caseErrorsData || [],
    '65',
    ResolutionTypes.PENDING,
  );

  return (
    <>
      <FormDivider label="Breakdown Location" sx={{ mb: 3 }} />
      <DynamicForm
        hookForm={breakdownLocationHookForm}
        fields={breakdownLocationFields}
        colspan={2.4}
        isEditMode={isEditMode && !!breakdownLocationData.uuid}
      />
      <FormDivider label="Tow Destinations" sx={{ mt: 2, mb: 3 }} />
      {isEditMode && (
        <Stack direction="row" mb={2} justifyContent="space-between">
          {permissions?.delete && (
            <DynamicFormDeleteAction
              title="Delete tow destination"
              label="Delete tow destination"
              content="Are you sure you want to delete this destination?"
              onSubmit={onDeleteTowDestination}
              disabled={selectedTowDestinations.length === 0}
            />
          )}
          {permissions?.create && hasError65Pending && (
            <Button
              variant="outlined"
              size="large"
              startIcon={<AddIcon />}
              onClick={onAddTowDestination}
            >
              Add Tow Destination
            </Button>
          )}
        </Stack>
      )}
      <form>
        <Box sx={{ mx: -4 }}>
          <ControlledFormTable<Partial<TowDestinationRecord>>
            hasSelectors={isEditMode && permissions?.delete}
            hookForm={towDestinationHookForm}
            fields={fields}
            isEditMode={isEditMode}
            indexName="uuid"
            onSelect={setSelectedTowDestinations}
            columns={towDestinationColumns}
            fieldArrayName="towDestination"
            resetTrigger={resetTrigger}
          />
        </Box>
      </form>
      <DynamicExpandableFormActions
        isEditMode={isEditMode}
        save={{
          onSubmit: handleSave,
          disabled: !isTowDestinationDirty && !isBreakdownLocationDirty,
        }}
        discard={{
          onSubmit: handleDiscard,
        }}
      />
      {isAddTowDestinationError && (
        <Typography color="error">
          An Error Has Occurred While Adding Tow Destination:
          {addTowDestinationErrorMessage}
        </Typography>
      )}
      {isUpdateTowDestinationError && (
        <Typography color="error">
          An Error Has Occurred While Updating Tow Destination:
          {updateTowDestinationErrorMessage}
        </Typography>
      )}
      {isDeleteTowDestinationError && (
        <Typography color="error">
          An Error Has Occurred While Deleting Tow Destination:
          {deleteTowDestinationErrorMessage}
        </Typography>
      )}
      {isUpdateBreakdownLocationError && (
        <Typography color="error">
          An Error Has Occurred While Updating Breakdown Location:
          {updateBreakdownLocationErrorMessage}
        </Typography>
      )}
    </>
  );
};

export default BreakdownAndTowEditor;
