import { useCallback, useState } from 'react';
import {
  CircularProgress,
  IconButton,
  Card,
  CardContent,
  Typography,
  Stack,
  Tooltip,
  Button,
} from '@mui/material';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import DownloadIcon from '@mui/icons-material/Download';
import moment from 'moment';
import DataTable, { DataTableColumn } from 'components/DataTable';
import { CRUDComponent } from 'components/CRUDNavigator';
import { LoaderCard } from 'components/Cards';
import { CallbackType, PaymentRequestSummaryData } from './types';
import { useGenerateMonthlyReport, usePaymentRequestFetchSummary } from './PaymentRequestQuery';
import { convertToCurrency } from 'utils/formatter';
import { convertToMMYYYY } from 'utils/convertDate';
import {
  usePaymentForecastsDownloadManyReports,
  usePaymentForecastsDownloadOneReports,
  usePaymentForecastsSendOneReport,
  usePaymentForecastsSendReports,
} from 'features/PaymentForecasts/PaymentForecastsQuery';
import { NotificationSnackbar, NotificationType } from 'components/Notifications';
import { downloadReportFile, getFileType } from 'features/Reports/utils';
import { ReportDto } from 'features/Reports/types';

const PaymentRequestList: CRUDComponent = ({ navigators }) => {
  const [notifications, setNotifications] = useState<NotificationType>();
  const { isLoading, data, refetch } = usePaymentRequestFetchSummary();
  const { mutateAsync: paymentForecastsSendReports, isLoading: isReportSendLoading } =
    usePaymentForecastsSendReports();
  const { mutateAsync: paymentForecastsSendOneReports, isLoading: isLoadingSendOneReport } =
    usePaymentForecastsSendOneReport();
  const { mutateAsync: paymentForecastsDownloadOneReports, isLoading: isReportOneDownloadLoading } =
    usePaymentForecastsDownloadOneReports();
  const {
    mutateAsync: paymentForecastsDownloadManyReports,
    isLoading: isReportManyDownloadLoading,
  } = usePaymentForecastsDownloadManyReports();
  const { mutateAsync: generateMonthlyReport, isLoading: isGenerateMonthlyReportLoading } =
    useGenerateMonthlyReport();
  const [currentPaymentGroupNumber, setCurrentPaymentGroupNumber] = useState<number>();

  const getPaymentRequestTableColumns = useCallback(
    (
      callback: (value: ReportDto, type: CallbackType) => void,
    ): DataTableColumn<PaymentRequestSummaryData>[] => {
      return [
        { id: 'paymentGroupNumber', label: 'Payment Forecast ID' },
        {
          id: 'startPeriod',
          label: 'Month/Year',
          accessor: (row) => convertToMMYYYY(row.startPeriod),
          sortable: true,
        },
        {
          id: 'totalCases',
          label: 'Total Cases',
          accessor: (row) => (
            <Tooltip title="Number of Payable Cases" placement="top">
              <Stack>{row?.totalCases}</Stack>
            </Tooltip>
          ),
        },
        {
          id: 'totalClubPayments',
          label: 'Total Club Payments',
          accessor: (row) => convertToCurrency(row.totalClubPayments),
        },
        {
          id: 'files',
          label: 'Files',
          accessor: (row: PaymentRequestSummaryData) => (
            <Stack>
              {row?.reports?.map((report) => (
                <Stack
                  key={`${report.name}_${report.recipient}`}
                  sx={{
                    minHeight: '24px',
                  }}
                >
                  {report.name}
                </Stack>
              ))}
            </Stack>
          ),
          minWidth: '280px',
        },
        {
          id: 'recipient',
          label: 'Recipients',
          accessor: (row: PaymentRequestSummaryData) => (
            <Stack>
              {row?.reports?.map((report) => (
                <Stack
                  key={`${report.name}_${report.recipient}`}
                  sx={{
                    minHeight: '24px',
                  }}
                >
                  {report.recipient}
                </Stack>
              ))}
            </Stack>
          ),
        },
        {
          id: 'sentAtDateTime',
          label: 'Sent Date',
          accessor: (row: PaymentRequestSummaryData) => (
            <Stack>
              {row?.reports?.map((report, reportId) => (
                <Stack
                  key={`${report.sentAtDateTime}-${reportId}`}
                  sx={{
                    minHeight: '24px',
                  }}
                >
                  {report.sentAtDateTime
                    ? moment(new Date(report.sentAtDateTime)).format('MM/DD/YYYY')
                    : '-'}
                </Stack>
              ))}
            </Stack>
          ),
        },
        {
          id: 'reports',
          label: 'Actions',
          accessor: (row: PaymentRequestSummaryData) =>
            (isReportOneDownloadLoading ||
              isReportSendLoading ||
              isReportManyDownloadLoading ||
              isLoadingSendOneReport) &&
            currentPaymentGroupNumber === row.paymentGroupNumber ? (
              <Stack spacing={1} p={1.5}>
                <CircularProgress size={24} />
              </Stack>
            ) : (
              <Stack>
                {row?.reports?.map((report) => (
                  <Stack
                    direction="row"
                    spacing={1}
                    key={`${report.name}_${report.recipient}`}
                    sx={{
                      minHeight: '24px',
                    }}
                  >
                    <Tooltip title="This action will send out multiple files" placement="top">
                      <span>
                        <IconButton
                          sx={{
                            padding: 0,
                            top: '-4px',
                            cursor: 'pointer',
                            color: 'primary.dark',
                            '&:hover': { color: 'primary.main' },
                          }}
                          disabled={!!report.sentAtDateTime}
                          onClick={(e) => {
                            setCurrentPaymentGroupNumber(row.paymentGroupNumber);
                            e.stopPropagation();
                            callback(report, CallbackType.SEND);
                          }}
                        >
                          <ForwardToInboxIcon sx={{ fontSize: 18 }} />
                        </IconButton>
                      </span>
                    </Tooltip>

                    <IconButton
                      sx={{
                        padding: 0,
                        top: '-4px',
                        cursor: 'pointer',
                        color: 'primary.dark',
                        '&:hover': { color: 'primary.main' },
                      }}
                      onClick={(e) => {
                        setCurrentPaymentGroupNumber(row.paymentGroupNumber);
                        e.stopPropagation();
                        callback(report, CallbackType.DOWNLOAD);
                      }}
                    >
                      <DownloadIcon sx={{ fontSize: 20 }} />
                    </IconButton>
                  </Stack>
                ))}
              </Stack>
            ),
        },
      ];
    },
    [
      isReportOneDownloadLoading,
      isReportSendLoading,
      isReportManyDownloadLoading,
      currentPaymentGroupNumber,
      isLoadingSendOneReport,
    ],
  );

  const handleReportAction = useCallback(
    (report: ReportDto, type: CallbackType) => {
      if (report.startPeriod && report.endPeriod && report.entityType) {
        if (type === CallbackType.DOWNLOAD) {
          if (report.uuid) {
            paymentForecastsDownloadOneReports(report.uuid)
              .then((res: any) => {
                const downloadBody = {
                  res,
                  reportName: report.name,
                  setNotifications,
                  fileType: getFileType(report.s3Path),
                  fileName: report.s3Path,
                };
                downloadReportFile(downloadBody);
              })
              .catch(() => {
                setNotifications({
                  name: `Download ${report.name} Report`,
                  type: 'error',
                  message: `The ${report.name} report has not been downloaded, try again later`,
                });
              });
          } else {
            paymentForecastsDownloadManyReports({
              startDate: report.startPeriod,
              endDate: report.endPeriod,
              reportEntityType: report.entityType,
            })
              .then((res: any) => {
                const month = report?.startPeriod?.split('-')[1];
                const year = report?.startPeriod?.split('-')[0];

                const downloadBody = {
                  res,
                  reportName: report.name,
                  setNotifications,
                  fileType: 'application/zip',
                  fileName: `reports_${report.entityType}_${month}${year}.zip`,
                };
                downloadReportFile(downloadBody);
              })
              .catch(() => {
                setNotifications({
                  name: `Download ${report.name} Report`,
                  type: 'error',
                  message: `The ${report.name} report has not been downloaded, try again later`,
                });
              });
          }
        }
        if (type === CallbackType.SEND) {
          if (report.uuid) {
            paymentForecastsSendOneReports(report.uuid).then(() => {
              setNotifications({
                name: `Send ${report.name} Report`,
                type: 'success',
                message: `The ${report.name} report has been sent successfully`,
              });
              refetch();
            });
          } else {
            paymentForecastsSendReports({
              startDate: report.startPeriod,
              endDate: report.endPeriod,
              reportEntityType: report.entityType,
            })
              .then(() => {
                setNotifications({
                  name: `Send ${report.name} Report`,
                  type: 'success',
                  message: `The ${report.name} report has been sent successfully`,
                });
                refetch();
              })
              .catch(() => {
                setNotifications({
                  name: `Send ${report.name} Report`,
                  type: 'error',
                  message: `The ${report.name} report has not been sent, try again later`,
                });
              });
          }
        }
      }
    },
    [
      paymentForecastsDownloadOneReports,
      paymentForecastsDownloadManyReports,
      paymentForecastsSendOneReports,
      refetch,
      paymentForecastsSendReports,
    ],
  );

  const handleRowClick = useCallback(
    (row: PaymentRequestSummaryData) => {
      navigators.toIndividualScreen(row.paymentGroupNumber.toString() ?? '', {
        startPeriod: row.startPeriod,
        endPeriod: row.endPeriod,
      });
    },
    [navigators],
  );

  const handleGenerateMonthlyReport = useCallback(() => {
    generateMonthlyReport()
      .then(() => {
        refetch().then(() => {
          setNotifications({
            name: 'Generate Monthly Report',
            type: 'success',
            message: 'The monthly report has been generated successfully',
          });
        });
      })
      .catch((error) => {
        refetch().then(() => {
          setNotifications({
            name: 'Generate Monthly Report',
            type: 'error',
            message:
              error?.response?.data?.message ??
              'The monthly report has not been generated, try again later',
          });
        });
      });
  }, [generateMonthlyReport, refetch]);

  if (isLoading) {
    return <LoaderCard label="Loading Payment Requests List ...." sx={{ p: 2 }} />;
  }

  if (data) {
    return (
      <Card>
        <CardContent>
          <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
            <Typography fontWeight="bold" component="div">
              Payment Requests
            </Typography>
            <Button
              variant="contained"
              onClick={handleGenerateMonthlyReport}
              disabled={isGenerateMonthlyReportLoading}
            >
              Generate Monthly payment Request Report
            </Button>
          </Stack>
        </CardContent>
        <NotificationSnackbar
          clearNotification={() => setNotifications(undefined)}
          notification={notifications}
        />

        <DataTable
          columns={getPaymentRequestTableColumns(handleReportAction)}
          rows={data}
          onRowClick={handleRowClick}
        />
      </Card>
    );
  }

  return null;
};

export default PaymentRequestList;
