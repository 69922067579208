import { useQuery, useQueryClient } from 'react-query';
import {
  updateServicesProvided,
  fetchCaseServicesProvided,
  fetchTroubleCodes,
  updateTroubleCodeByUUID,
} from 'api/cases/manageCasesApi';
import { fetchServicesProvided } from 'api/servicesProvided';
import { ServiceProvidedDto } from 'api/servicesProvided/interfaces';
import { CaseServiceProvidedDto, TroubleCodeTypes } from 'api/cases/interfaces/ServiceProvidedDto';
import { useManagedMutation } from 'hooks/useReactQueryHelpers';
import { TroubleCodeDto } from 'api/cases/interfaces/TroubleCodeDto';
import { PriceType } from 'api/cases/enums/PriceType';

export interface CaseServiceProvidedData {
  uuid?: string | null;
  unitCount: number;
  unitOfMeasure: 'ML' | 'EA' | '';
  code: string;
  cost: number;
  name?: string;
  priceType?: PriceType;
}

export enum TroubleCodes {
  '7A' = 'Lockout - Key Delivery',
  '7B' = 'Lockout - Locksmith',
  'T0' = 'GOA',
  'T1' = 'Flat w/Spare',
  'T3' = 'Battery',
  'T5' = 'Fuel',
  'T6' = 'Tow',
  'T7' = 'Lockout',
  'T8' = 'Extrication',
  'T9' = 'Miscellaneous',
}

export interface TroubleCodeData {
  uuid: string;
  type: string;
  code: keyof typeof TroubleCodes | string;
  description: string;
}

type Props = {
  caseId: string;
  enabled: boolean;
  types?: TroubleCodeTypes[];
};

const mapCaseServicesProvidedDtoToData = (
  data: CaseServiceProvidedDto[],
): CaseServiceProvidedData[] => {
  return data.map((row) => {
    return {
      uuid: row.uuid,
      unitCount: row.unitCount,
      unitOfMeasure: row.code === 'T6' ? 'ML' : 'EA',
      code: row.code,
      cost: row.cost,
      priceType: row.priceType,
    };
  });
};

function mapTroubleCodesDtoToData(data: TroubleCodeDto[]): TroubleCodeData[] {
  return data.map((dto) => {
    const troubleCode = dto.code as keyof typeof TroubleCodes;

    return {
      ...dto,
      code: troubleCode,
      description: TroubleCodes[troubleCode] || dto.description,
    };
  });
}

const mapTroubleCodeDataToDto = (data: Partial<TroubleCodeData>): Partial<TroubleCodeDto> => {
  const { code, description } = data;
  return {
    code,
    description,
  };
};

const mapServicesProvidedDataToDto = (
  data: Partial<CaseServiceProvidedData>,
): Partial<CaseServiceProvidedDto> => ({
  uuid: data.uuid?.startsWith('fakeUuid-') ? null : data.uuid,
  code: data.code,
  cost: data.cost,
  unitCount: data.unitCount,
  priceType: data.priceType,
});

export function useCaseServiceTroubleCodes({ caseId, types, enabled }: Props) {
  return useQuery(['caseServiceTroubleCodes', caseId], () => fetchTroubleCodes(caseId, types), {
    enabled,
    select: mapTroubleCodesDtoToData,
  });
}

export function useUpdateTroubleCode(caseId: string) {
  const queryClient = useQueryClient();

  return useManagedMutation({
    mutationFn: (troubleCodeData: Partial<TroubleCodeData>) =>
      updateTroubleCodeByUUID(
        caseId,
        troubleCodeData.uuid || '',
        mapTroubleCodeDataToDto(troubleCodeData),
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(['caseServiceTroubleCodes']);
    },
  });
}

export function useCaseServicesProvided(callId: string, enabled = true) {
  return useQuery(['caseServicesProvided', callId], () => fetchCaseServicesProvided(callId), {
    enabled,
    select: mapCaseServicesProvidedDtoToData,
  });
}

export function useServicesProvided(enabled = true) {
  return useQuery<ServiceProvidedDto[]>(
    ['servicesProvided'],
    () => fetchServicesProvided().then((res) => res.content as ServiceProvidedDto[]),
    { enabled },
  );
}

export function useUpdateServicesProvided(caseId: string) {
  return useManagedMutation({
    mutationFn: (services: Partial<CaseServiceProvidedDto>[]) =>
      updateServicesProvided(
        caseId,
        services.map((service) => mapServicesProvidedDataToDto(service)),
      ),
  });
}
