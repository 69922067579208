export enum RbpCaseStatus {
  APPROVED_FOR_BILLING = 'APPROVED_FOR_BILLING',
  BILLING_OK = 'BILLING_OK',
  CANCELLED = 'CANCELLED',
  CLIENT_REVIEW = 'CLIENT_REVIEW',
  CLIENT_RULE_NOK = 'CLIENT_RULE_NOK',
  CREATED = 'CREATED',
  ENRICH_FIXED = 'ENRICH_FIXED',
  ENRICH_NOK = 'ENRICH_NOK',
  ENRICHED = 'ENRICHED',
  FINANCIAL_CREATED = 'FINANCIAL_CREATED',
  FINANCIAL_FIXED = 'FINANCIAL_FIXED',
  FINANCIAL_NOK = 'FINANCIAL_NOK',
  FINANCIAL_OK = 'FINANCIAL_OK',
  FINANCIAL_RULE_OK = 'FINANCIAL_RULE_OK',
  FIXED = 'FIXED',
  PARKED = 'PARKED',
  RECEIVED = 'RECEIVED',
  REQUEST_CLIENT_REVIEW = 'REQUEST_CLIENT_REVIEW',
  RULE_NOK = 'RULE_NOK',
  RULE_OK = 'RULE_OK',
}

export const RbpCaseStatusDescription: Record<RbpCaseStatus, string> = {
  APPROVED_FOR_BILLING: 'APPROVED FOR BILLING',
  BILLING_OK: 'BILLING OK',
  CANCELLED: 'CANCELLED',
  CLIENT_REVIEW: 'CLIENT REVIEW',
  CLIENT_RULE_NOK: 'CLIENT RULE NOK',
  CREATED: 'CREATED',
  ENRICH_FIXED: 'ENRICH FIXED',
  ENRICH_NOK: 'ENRICH NOK',
  ENRICHED: 'ENRICHED',
  FINANCIAL_CREATED: 'FINANCIAL CREATED',
  FINANCIAL_FIXED: 'FINANCIAL FIXED',
  FINANCIAL_NOK: 'FINANCIAL NOK',
  FINANCIAL_OK: 'FINANCIAL OK',
  FINANCIAL_RULE_OK: 'FINANCIAL RULE OK',
  FIXED: 'FIXED',
  PARKED: 'PARKED',
  RECEIVED: 'RECEIVED',
  REQUEST_CLIENT_REVIEW: 'REQUEST CLIENT REVIEW',
  RULE_NOK: 'RULE NOK',
  RULE_OK: 'RULE OK',
};

export enum RbpCaseStatusGroup {
  PRIMARY = 'Primary Statuses',
  TRANSIENT = 'Transient Statuses',
}

export const TransientRbpCaseStatuses = [
  RbpCaseStatus.CREATED,
  RbpCaseStatus.FIXED,
  RbpCaseStatus.RULE_OK,
];
