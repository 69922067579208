import { useCallback, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { routes } from 'routes';
import {
  Breadcrumbs,
  Card,
  CardContent,
  IconButton,
  Typography,
  Stack,
  styled,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { DynamicActions } from 'components/Dynamic';
import { CaseViewStatus } from './CaseViewStatus';
import { useCaseServiceTroubleCodes } from '../ServicesProvided/ServicesProvidedQuery';
import { CaseData } from 'features/Cases/interfaces/CaseData';
import CaseAddAdjustmentDialog from './CaseAddAdjustmentDialog';
import CaseCancelActionDialog from './CaseCancelActionDialog';
import CaseAddToBRDialog from './CaseAddToBRDialog';
import { RbpCaseStatus } from 'core/enums/RbpCaseStatus';
import { useRapCoreFeaturePermissions } from 'auth/permissions/useRapCoreFeaturePermission';
import ForceClsActionDialog from './ForceClsActionDialog';
import CaseUncancelActionDialog from './CaseUncancelActionDialog';

interface Props {
  caseData: CaseData;
  onNext?: () => void;
  onPrevious?: () => void;
  previousTitle?: string;
  previousRoute?: string;
}

const StickyCard = styled(Card)(() => ({ position: 'sticky', top: 64, zIndex: 2 }));

export function CaseViewHeader({
  caseData,
  onNext,
  onPrevious,
  previousTitle,
  previousRoute,
}: Props) {
  const { search, state } = useLocation();
  const internalState = state as { fromBulkActions?: boolean };

  const { permissions: addCasePermissions } = useRapCoreFeaturePermissions('attachCaseToBR');
  const { permissions: clsPermissions } = useRapCoreFeaturePermissions('forceCls');
  const { permissions: uncancelCasePermissions } = useRapCoreFeaturePermissions('uncancelCase');
  const { permissions: cancelCasePermissions } = useRapCoreFeaturePermissions('cancelCase');

  const [adjustmentDialog, setAdjustmentDialog] = useState(false);
  const [cancelCaseDialog, setCancelCaseDialog] = useState(false);
  const [forceClsDialog, setForceClsDialog] = useState(false);
  const [caseAddToBRDialog, setCaseAddToBRDialogeDialog] = useState(false);
  const [uncancelCaseDialog, setUncancelCaseDialog] = useState(false);

  const { data: troubleCodes = [] } = useCaseServiceTroubleCodes({
    caseId: caseData.uuid,
    enabled: true,
  });

  const getOriginalServiceType = useCallback((): string => {
    if (!troubleCodes.length) {
      return '';
    }

    const { code = '', description = '' } = troubleCodes[0];
    const separator = (code && description) || (!code && !description) ? ' - ' : '';

    return `${code}${separator}${description}`;
  }, [troubleCodes]);

  const getSubMenuActions = () => {
    return [
      ...(addCasePermissions?.update
        ? [
            {
              label: 'Add Case to Billing Request',
              onClick: () => setCaseAddToBRDialogeDialog(true),
              hasCustomAction: true,
              disabled: caseData.rbpCaseStatus !== RbpCaseStatus.FINANCIAL_OK,
            },
          ]
        : []),
      ...(clsPermissions?.update &&
      !caseData.rapCallClosedDateTime &&
      caseData.callStatus !== 'CLS' &&
      (!caseData.rbpCaseStatus || caseData.rbpCaseStatus === RbpCaseStatus.ENRICHED)
        ? [
            {
              label: 'Force CLS',
              onClick: () => setForceClsDialog(true),
              hasCustomAction: true,
            },
          ]
        : []),
      {
        label: 'Cancel Case',
        onClick: () => setCancelCaseDialog(true),
        hasCustomAction: true,
        disabled:
          !cancelCasePermissions.update ||
          caseData.rbpCaseStatus === RbpCaseStatus.BILLING_OK ||
          caseData.rbpCaseStatus === RbpCaseStatus.CANCELLED,
      },
      ...(uncancelCasePermissions?.update && caseData.rbpCaseStatus === RbpCaseStatus.CANCELLED
        ? [
            {
              label: 'Uncancel Case',
              onClick: () => setUncancelCaseDialog(true),
              hasCustomAction: true,
            },
          ]
        : []),
    ];
  };

  return (
    <>
      <StickyCard data-testid="CaseViewHeader">
        <CardContent>
          <Stack justifyContent="space-between" flexDirection="row">
            <Breadcrumbs
              color="text.primary"
              aria-label="breadcrumb"
              separator={<NavigateNextIcon />}
              sx={{ mb: 3 }}
              data-testid="BreadcrumbHeader"
            >
              <Link
                to={previousRoute || `${routes.casePage}${search}`}
                state={{
                  fromBulkActions: internalState?.fromBulkActions,
                }}
              >
                <Typography color="text.primary" sx={{ fontSize: '14px' }}>
                  {previousTitle || 'Case Results'}
                </Typography>
              </Link>
              <Typography color="text.primary" sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                Case Details
              </Typography>
            </Breadcrumbs>

            <CaseViewStatus status={caseData.callStatus} rbpCaseStatus={caseData.rbpCaseStatus} />
          </Stack>

          <DynamicActions
            visible={true}
            isEditMode={false}
            leftPanel={
              <>
                <IconButton disabled={!onPrevious} onClick={onPrevious}>
                  <ChevronLeftIcon />
                </IconButton>
                <Typography variant="h5">RAP Case ID {caseData.rapCaseId}</Typography>
                <IconButton disabled={!onNext} onClick={onNext}>
                  <ChevronRightIcon />
                </IconButton>
              </>
            }
            centralPanel={
              <>
                <Stack justifyContent="space-around" flexDirection="row" flexGrow={1}>
                  <Stack flexDirection="row">
                    <Typography component={'span'} variant="h6">
                      Program Name:&nbsp;
                    </Typography>
                    <Typography component={'span'} variant="h6" sx={{ fontWeight: 'bold' }}>
                      {caseData.programName}
                    </Typography>
                  </Stack>
                  <Stack flexDirection="row">
                    <Typography component={'span'} variant="h6">
                      Original Service Type:&nbsp;
                    </Typography>
                    <Typography component={'span'} variant="h6" sx={{ fontWeight: 'bold' }}>
                      {getOriginalServiceType()}
                    </Typography>
                  </Stack>
                </Stack>
              </>
            }
            subMenuActions={getSubMenuActions()}
          />
        </CardContent>
      </StickyCard>

      <CaseAddAdjustmentDialog
        setVisible={setAdjustmentDialog}
        visible={adjustmentDialog}
        onConfirmAddInvoice={() => setAdjustmentDialog(false)}
        caseDataUuid={caseData.uuid}
        defaultAdjustmentValues={{
          rapCaseId: caseData.rapCaseId,
          clientName: caseData.clientName,
          payable: getOriginalServiceType(),
          statusReason: caseData.callStatus,
        }}
      />

      <CaseCancelActionDialog
        setVisible={setCancelCaseDialog}
        visible={cancelCaseDialog}
        onConfirmCancelCase={() => setCancelCaseDialog(false)}
        caseData={caseData}
      />

      <CaseAddToBRDialog
        setVisible={setCaseAddToBRDialogeDialog}
        visible={caseAddToBRDialog}
        onConfirmAddtoBR={() => setCaseAddToBRDialogeDialog(false)}
        caseData={caseData}
      />

      <ForceClsActionDialog
        setVisible={setForceClsDialog}
        visible={forceClsDialog}
        onConfirm={() => setForceClsDialog(false)}
        caseData={caseData}
      />

      <CaseUncancelActionDialog
        setVisible={setUncancelCaseDialog}
        visible={uncancelCaseDialog}
        onConfirmCancelCase={() => setUncancelCaseDialog(false)}
        caseData={caseData}
      />
    </>
  );
}
