import { useState } from 'react';
import { FormControl, Box, Typography, Button, TextField, FormHelperText } from '@mui/material';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import { useDomainAuth } from 'auth/domain-auth/useDomainAuth';
import { useAddUserComment } from 'features/Cases/queries';
import { UserCommentTypes } from 'core/enums/CaseDetails';
import { commentValidationSchema } from './config';
import { useCaseContext } from 'features/Cases/CaseProvider';

const AddCommentButton = styled(Button)(() => ({
  alignSelf: 'end',
  '.MuiButton-startIcon': { marginRight: '4px', '.MuiSvgIcon-root': { fontSize: '24px' } },
}));

interface AddCommentProps {
  caseUuid: string;
}

const AddComment = ({ caseUuid }: AddCommentProps) => {
  const { user } = useDomainAuth();
  const [comment, setComment] = useState<string>('');
  const { isError, mutateAsync, isLoading } = useAddUserComment(caseUuid);
  const [formError, setFormError] = useState(false);
  const { resetAll } = useCaseContext();

  const validateForm = () => {
    const isValid = commentValidationSchema.isValidSync({ comment });
    setFormError(!isValid);
    return isValid;
  };

  const handleSubmit = () => {
    if (validateForm() && user) {
      mutateAsync({ text: comment, type: UserCommentTypes.NORMAL })
        .then(() => {
          const searchParams = new URLSearchParams(location.search);
          if (!searchParams.get('openDefaultErrorPanel')) {
            searchParams.append('openDefaultErrorPanel', 'active');
            window.history.pushState({}, '', `${location.href}?${searchParams.toString()}`);
          }
          setComment('');
          resetAll();
        })
        .catch();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
    setFormError(false);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <FormControl fullWidth sx={{ mb: formError ? 0 : 2 }}>
        <TextField
          id="user-comment-textfield"
          label="Comment"
          value={comment}
          size="small"
          rows={3}
          disabled={isLoading}
          multiline
          required
          onChange={handleChange}
          placeholder="Add a comment..."
          error={formError}
          InputLabelProps={{ shrink: true }}
        />
      </FormControl>
      {formError && <FormHelperText error={formError}>Comment is a required field</FormHelperText>}
      <AddCommentButton
        startIcon={<AddIcon />}
        disabled={isLoading}
        variant="outlined"
        onClick={handleSubmit}
      >
        Add Comment
      </AddCommentButton>

      {/* TODO: Adjust error message with design */}
      {isError && <Typography>An Error Has Occurred While Updating Case Comments</Typography>}
    </Box>
  );
};

export default AddComment;
