export enum ReportEntityType {
  ALL_IN_ONE = 'ALL_IN_ONE',
  FORECAST = 'FORECAST',
  PAYMENT_REQUEST = 'PAYMENT_REQUEST',
  PAYMENT_SUMMARY = 'PAYMENT_SUMMARY',
  PAYMENT_DETAIL = 'PAYMENT_DETAIL',
  PAYMENT_DETAIL_TAB = 'PAYMENT_DETAIL_TAB',
  PAYMENT_DETAIL_FIXED = 'PAYMENT_DETAIL_FIXED',
  REV_SHARE = 'REV_SHARE',
  DOUBLE_REV_SHARE = 'DOUBLE_REV_SHARE',
  ACCRUAL = 'ACCRUAL',
  ALLOCATION = 'ALLOCATION',
  TABLEAU = 'TABLEAU',
}

export enum ReportStatus {
  APPROVED = 'APPROVED',
  ASSEMBLING = 'ASSEMBLING',
  PENDING = 'PENDING',
  NOT_READY = 'NOT_READY',
  READY = 'READY',
  SENT = 'SENT',
  ERROR = 'ERROR',
}

interface BaseDto {
  uuid: string;
  createdAtDateTime?: string;
  updatedAtDateTime?: string;
  createdByUserId?: string;
  updatedByUserId?: string;
}

export interface ReportDto extends BaseDto {
  name: string;
  number?: string;
  s3Path: string;
  reportStatus?: ReportStatus;
  entityType?: ReportEntityType;
  entityUuid?: string;
  startPeriod?: string;
  endPeriod?: string;
  sentAtDateTime?: string;
  recipient?: string;
  clientName: string;
  billingNumber: number;
  uuids?: string[];
}

export interface ReportSummaryDto {
  number: string;
  name: string;
  count: number;
  monthYear: string;
  reports: ReportDto[];
}
