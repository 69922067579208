import { NotificationType } from 'components/Notifications';

export function getFileType(path: string): string {
  const extension = path.slice(-3);

  return `application/${extension}`;
}

export const downloadReportFile = ({
  res,
  reportName,
  setNotifications,
  fileType,
  fileName,
}: {
  res: any;
  reportName: string;
  setNotifications: React.Dispatch<React.SetStateAction<NotificationType | undefined>>;
  fileType: string;
  fileName?: string;
}) => {
  // Create a blob from the PDF Stream
  const pdfBlob = new Blob([res], { type: fileType });

  // Create a URL for the blob
  const pdfUrl = URL.createObjectURL(pdfBlob);

  // Create an element to trigger the download
  const downloadLink = document.createElement('a');
  downloadLink.href = pdfUrl;

  downloadLink.setAttribute('download', fileName ?? '');
  document.body.appendChild(downloadLink);
  downloadLink.click();

  // Clean up and remove the link
  document.body.removeChild(downloadLink);
  URL.revokeObjectURL(pdfUrl);
  setNotifications({
    name: `Download ${reportName} Report`,
    type: 'success',
    message: `The ${reportName} report has been downloaded successfully`,
  });
};
