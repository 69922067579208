import { useMemo, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  Typography,
  Stack,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ReplayIcon from '@mui/icons-material/Replay';
import EmailIcon from '@mui/icons-material/Email';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import * as yup from 'yup';
import { ValidationError } from 'yup';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useForm } from 'react-hook-form';

import DialogConfirmation from 'components/DialogConfirmation';
import { yupResolver } from '@hookform/resolvers/yup';
import CreditCardReceipt from './CreditCardReceipt';
import { CreditCardData } from 'features/CreditCards/types';
import { CaseGeneralInfoData } from '../GeneralInfo/interface/CaseGeneralInfoData';
import { BreakdownLocationDto } from 'api/cases/interfaces/BreakdownLocationDto';
import { useUpdateCreditCardStatus, useRefundCreditCard } from './CreditCardQuery';
import { TransactionStatus } from 'core/enums/CreditCards';
import { RbpCaseStatus } from 'core/enums/RbpCaseStatus';
import { useFeaturePermissionDetails } from 'auth/permissions/useFeaturePermission';
import { useCaseContext } from 'features/Cases/CaseProvider';
import { CaseFinancialSingleLineItemViewDto } from 'api/cases/interfaces/CaseFinancialDto';
import { CreditCardReceiptDto } from 'api/cases/interfaces/CreditCardReceipt';

interface CreditCardHeaderProps {
  statusCode: string;
  data: CreditCardData;
  generalInfo?: CaseGeneralInfoData;
  receiptData?: CreditCardReceiptDto;
  breakdownLocation?: BreakdownLocationDto;
  caseFinancialItems?: CaseFinancialSingleLineItemViewDto[];
}

export const CustomerRefundValidationSchema = (maxRefund: number) =>
  yup.object({
    refundAmount: yup
      .number()
      .positive()
      .transform((value) => (isNaN(value) ? undefined : value))
      .label('Refund Amount')
      .test('max-value', 'Amount exceeds the charged amount', (value) => {
        return maxRefund >= (value || 0);
      }),
    refundReason: yup.string().required().label('Refund Reason'),
  });

const CreditCardHeader = ({
  statusCode,
  data,
  generalInfo,
  breakdownLocation,
  caseFinancialItems,
  receiptData,
}: CreditCardHeaderProps) => {
  const [cancelAuthorization, setCancelAuthorization] = useState(false);
  const [customerRefundDialog, setCustomerRefundDialog] = useState(false);
  const [customerReceiptDialog, setCustomerReceiptDialog] = useState(false);
  const [refundError, setRefundError] = useState(false);
  const [refundErrorMessage, setRefundErrorMessage] = useState('');
  const { resetAll } = useCaseContext();
  const creditCardTable = useFeaturePermissionDetails({ table: 'CaseCreditCardTransactionDto' });

  const { mutateAsync: cancelPreAuthorization, isLoading: isLoadingCancelPreAuthorization } =
    useUpdateCreditCardStatus();

  const { mutateAsync: refundCreditCard, isLoading: isLoadingRefundCreditCard } =
    useRefundCreditCard();

  const onConfirmCancelPreAuth = async () => {
    try {
      await cancelPreAuthorization({
        uuid: data?.rbpCase?.uuid,
        creditCardUuid: data.uuid,
        status: TransactionStatus.CANCEL_PRE_AUTH_PENDING,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setCancelAuthorization(false);
    }
  };

  const onDownloadReceipt = () => {
    const input = document.getElementById('credit-card-receipt');
    html2canvas(input!).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      pdf.addImage(imgData, 'JPEG', 15, 10, 180, 200);
      pdf.save('receipt.pdf');
    });
  };

  const refundSchema = useMemo(() => {
    return CustomerRefundValidationSchema(Number(data.cardChargeAmount) || 0);
  }, [receiptData]);

  const {
    register,
    getValues,
    reset: resetRefundForm,
    formState,
    trigger,
  } = useForm({
    defaultValues: {
      refundAmount: 0,
      refundReason: '',
    },
    resolver: yupResolver(refundSchema),
  });

  const validateForm = async (field: 'refundReason' | 'refundAmount') => {
    setRefundErrorMessage('');
    trigger(field);
  };

  const onConfirmRefund = async () => {
    trigger(['refundAmount', 'refundReason']);
    if (!formState.isValid) return;
    setRefundError(false);
    const { refundAmount, refundReason } = getValues();
    try {
      await refundCreditCard({
        uuid: data?.rbpCase?.uuid,
        creditCardUuid: data.uuid,
        status: TransactionStatus.REFUND_PENDING,
        refundReason,
        refundAmount: Number(refundAmount),
      });
      resetAll();
      resetRefundForm();
      setCustomerRefundDialog(false);
    } catch (error: any) {
      setRefundError(true);
      const backendMessage = error?.response?.data?.message || '';
      setRefundErrorMessage(
        backendMessage.split('message":"').length > 1
          ? ` ${backendMessage.split('message":"')[1].split('"')[0]}`
          : '',
      );
    }
  };

  const openRefundModal = () => {
    setCustomerRefundDialog(true);
    setRefundErrorMessage('');
  };

  const shouldShowCancelAuthorization = creditCardTable?.fields.transactionStatus?.update;
  const shouldShowCustomerRefund =
    creditCardTable?.fields.refundReason?.update && creditCardTable?.fields.refundAmount?.update;
  const disableCustomerRefundButton =
    ![TransactionStatus.SETTLED].includes(data.transactionStatus) ||
    Boolean(data.refundAmount && data.refundDate && data.refundReason);

  const allowCancelPreAuth =
    generalInfo?.rbpCaseStatus &&
    [RbpCaseStatus.RULE_NOK].includes(generalInfo.rbpCaseStatus) &&
    [TransactionStatus.PRE_AUTHORIZED].includes(data.transactionStatus);

  const allowReceiptButton =
    generalInfo?.rbpCaseStatus &&
    [RbpCaseStatus.FINANCIAL_OK, RbpCaseStatus.BILLING_OK].includes(generalInfo.rbpCaseStatus);

  const disableReceiptButton = data.transactionStatus !== TransactionStatus.SETTLED;

  return (
    <>
      <Box display="flex" justifyContent="space-between" sx={{ padding: '0 32px' }}>
        <Typography variant="h6">
          Transaction Status:{' '}
          <Typography variant="h6" component="span" sx={{ fontWeight: 'bold' }}>
            {statusCode}
          </Typography>
        </Typography>
        <Box display="flex" gap="8px">
          {/* SHOW OR HIDE BASED ON transactionStatus */}
          {shouldShowCancelAuthorization && (
            <Button
              onClick={() => setCancelAuthorization(true)}
              variant="contained"
              sx={{ gap: 1 }}
              disabled={!allowCancelPreAuth}
            >
              <CloseIcon sx={{ color: 'white' }} />
              Cancel pre-authorization
            </Button>
          )}
          {allowReceiptButton && (
            <Button
              onClick={() => setCustomerReceiptDialog(true)}
              variant="contained"
              sx={{ gap: 1 }}
              disabled={disableReceiptButton}
            >
              <ReceiptLongIcon sx={{ color: 'white' }} />
              View receipt
            </Button>
          )}
          {/* SHOW OR HIDE BASED ON reason and refundAmount */}
          {shouldShowCustomerRefund && (
            <Button
              onClick={openRefundModal}
              variant="contained"
              sx={{ gap: 1 }}
              disabled={disableCustomerRefundButton}
            >
              <ReplayIcon sx={{ color: 'white' }} />
              Issue customer refund
            </Button>
          )}
        </Box>
      </Box>
      {/* CANCEL DIALOG */}
      <DialogConfirmation
        open={cancelAuthorization}
        title="Cancel Pre-Authorization"
        content="Are you sure you want to cancel pre-authorization?"
        onClose={() => setCancelAuthorization(false)}
        onConfirm={onConfirmCancelPreAuth}
        labelConfirm="Proceed"
        labelCancel="CLOSE"
        isLoading={isLoadingCancelPreAuthorization}
      />
      {/* CUSTOMER REFUND DIALOG */}
      <DialogConfirmation
        open={customerRefundDialog}
        title="Issue customer refund"
        onClose={() => setCustomerRefundDialog(false)}
        onConfirm={onConfirmRefund}
        labelConfirm="Issue Refund"
        isLoading={isLoadingRefundCreditCard}
      >
        <TextField
          id="refundAmount"
          label="Refund Amount"
          {...register('refundAmount')}
          fullWidth
          onKeyUp={() => validateForm('refundAmount')}
          onBlur={() => validateForm('refundAmount')}
          error={Boolean(formState.errors.refundAmount)}
          type="number"
        />
        {formState.errors.refundAmount?.message && (
          <Typography color="error" marginTop={1} width={411}>
            {formState.errors.refundAmount?.message}
          </Typography>
        )}
        <TextField
          sx={{ mt: 2, height: 120 }}
          inputProps={{ style: { height: 100 } }}
          id="refundReason"
          label="Refund Reason"
          {...register('refundReason')}
          error={Boolean(formState.errors.refundReason)}
          onKeyDown={() => validateForm('refundReason')}
          onBlur={() => validateForm('refundReason')}
          multiline
          fullWidth
          required
        />
        {formState.errors.refundReason?.message && (
          <Typography color="error" marginTop={3} width={411}>
            {formState.errors.refundReason?.message}
          </Typography>
        )}
        {refundError && (
          <Typography color="error" marginTop={3} width={411}>
            An Error Has Occurred While Adding Refund:
            {refundErrorMessage}
          </Typography>
        )}
      </DialogConfirmation>
      {/* CUSTOMER RECEIPT DIALOG */}
      <DialogConfirmation
        open={customerReceiptDialog}
        title="Credit Card Receipt"
        onClose={() => setCustomerReceiptDialog(false)}
        onConfirm={onDownloadReceipt}
        labelConfirm="Download"
        sx={{
          '& .MuiDialog-paper': {
            width: 'fit-content',
            maxWidth: 980,
          },
          '& .MuiDialogContent-root': {
            overflowY: 'unset',
          },
        }}
      >
        <CreditCardReceipt
          breakdownLocation={breakdownLocation}
          generalInfo={generalInfo}
          caseFinancialItems={caseFinancialItems}
          receiptData={receiptData}
        />
      </DialogConfirmation>
      {/* CUSTOMER RECEIPT DIALOG */}
      <Dialog
        open={customerReceiptDialog}
        onClose={() => setCustomerReceiptDialog(false)}
        sx={{
          '& .MuiDialog-paper': {
            width: 'fit-content',
            maxWidth: 980,
          },
          '& .MuiDialogContent-root': {
            overflowY: 'unset',
          },
        }}
      >
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="subtitle1" component={'span'} sx={{ fontWeight: 700 }}>
              Credit Card Receipt
            </Typography>
            <IconButton onClick={() => setCustomerReceiptDialog(false)}>
              <CloseIcon color="secondary" />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent>
          <CreditCardReceipt
            breakdownLocation={breakdownLocation}
            generalInfo={generalInfo}
            caseFinancialItems={caseFinancialItems}
            receiptData={receiptData}
          />
        </DialogContent>

        <DialogActions sx={{ justifyContent: 'space-between', px: 2, pb: 3 }}>
          <Button variant="outlined" onClick={() => setCustomerReceiptDialog(false)}>
            Close
          </Button>
          <Stack spacing={1} direction="row">
            <Button variant="outlined" onClick={onDownloadReceipt} startIcon={<SaveAltIcon />}>
              Download
            </Button>
            <Button variant="contained" startIcon={<EmailIcon />}>
              Email receipt to customer
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreditCardHeader;
