import { useCallback, useMemo, useState } from 'react';
import { Box, CircularProgress, IconButton, Paper, Stack } from '@mui/material';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import DownloadIcon from '@mui/icons-material/Download';
import sumBy from 'lodash.sumby';
import moment from 'moment';
import { useLocation, useParams } from 'react-router-dom';
import BreadcrumbHeader, { Breadcrumb } from 'components/BreadCrumbHeader';
import { CRUDComponent } from 'components/CRUDNavigator';
import { CRUDPageableLayout } from 'components/CRUDPageable';
import { LoaderCard } from 'components/Cards';
import {
  usePaymentForecastsDownloadOneReports,
  usePaymentForecastsFetchOneQuery,
  usePaymentForecastsSendOneReport,
} from './PaymentForecastsQuery';
import { convertToMMYYYY } from 'utils/convertDate';
import { CallbackType, PaymentForecastsInternalData } from './types';
import DataTable, { DataTableColumn } from 'components/DataTable';
import { convertToCurrency } from 'utils/formatter';
import { NotificationSnackbar, NotificationType } from 'components/Notifications';
import { downloadReportFile } from 'features/Reports/utils';
import { ReportDto } from 'features/Reports/types';

const PaymentForecastsDetail: CRUDComponent = ({ navigators }) => {
  const location = useLocation();
  const params = useParams();
  const [notifications, setNotifications] = useState<NotificationType>();
  const { mutateAsync: paymentForecastsSendReports, isLoading: isLoadingSendOneReport } =
    usePaymentForecastsSendOneReport();
  const { mutateAsync: paymentForecastsDownloadReports, isLoading: isLoadingDownloadReport } =
    usePaymentForecastsDownloadOneReports();
  const [currentClubName, setCurrentClubName] = useState<string>();
  const search = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const breadcrumbs: Breadcrumb[] = [
    {
      url: '..',
      label: 'Payment Forecasts',
    },
    {
      url: '',
      label: `Payment Forecast ID ${params.uuid} - ${convertToMMYYYY(
        search.get('startPeriod') ?? '',
      )}`,
    },
  ];

  const { isLoading, data, refetch } = usePaymentForecastsFetchOneQuery({
    startDate: search.get('startPeriod') ?? '',
    endDate: search.get('endPeriod') ?? '',
  });

  const getPaymentRequestTableColumns = useCallback(
    (
      callback: (value: ReportDto, type: CallbackType) => void,
    ): DataTableColumn<PaymentForecastsInternalData>[] => {
      return [
        {
          id: 'clubName',
          label: 'Club/Vendor Name',
          accessor: (row) => `${row.clubCode} - ${row.clubName}`,
          Footer: () => 'Total',
        },
        {
          id: 'totalCases',
          label: 'Total Call Count',
          Footer: (rows) => sumBy(rows, (row) => row.totalCases),
        },
        {
          id: 'totalRevenueShareAmount',
          label: 'Revenue Share Amount',
          accessor: (row) => convertToCurrency(row.totalRevenueShareAmount),
          Footer: (rows) => convertToCurrency(sumBy(rows, (row) => row.totalRevenueShareAmount)),
        },
        {
          id: 'totalPayableAmount',
          label: 'Payable Amount',
          accessor: (row) => convertToCurrency(row.totalPayableAmount),
          Footer: (rows) => convertToCurrency(sumBy(rows, (row) => row.totalPayableAmount)),
        },
        {
          id: 'status',
          label: 'Status',
          accessor: (row: PaymentForecastsInternalData) => (
            <Stack>
              {row?.reports?.map((report) => (
                <Stack
                  key={report.reportStatus}
                  sx={{
                    minHeight: '24px',
                  }}
                >
                  {report.reportStatus ?? '-'}
                </Stack>
              ))}
            </Stack>
          ),
          Footer: () => '',
        },
        {
          id: 'files',
          label: 'Files',
          accessor: (row: PaymentForecastsInternalData) => (
            <Stack>
              {row?.reports?.map((report) => (
                <Stack
                  key={report.name}
                  sx={{
                    minHeight: '24px',
                  }}
                >
                  {report.name ?? '-'}
                </Stack>
              ))}
            </Stack>
          ),
          Footer: () => '',
          minWidth: '200px',
        },
        {
          id: 'recipient',
          label: 'Recipients',
          accessor: (row: PaymentForecastsInternalData) => (
            <Stack>
              {row?.reports?.map((report) => (
                <Stack
                  key={report.recipient}
                  sx={{
                    minHeight: '24px',
                  }}
                >
                  {report.recipient ?? '-'}
                </Stack>
              ))}
            </Stack>
          ),
          Footer: () => '',
        },
        {
          id: 'sentAtDateTime',
          label: 'Sent Date',
          accessor: (row: PaymentForecastsInternalData) => (
            <Stack>
              {row?.reports?.map((report, reportId) => (
                <Stack
                  key={`${report.sentAtDateTime}-${reportId}`}
                  sx={{
                    minHeight: '24px',
                  }}
                >
                  {report.sentAtDateTime
                    ? moment(new Date(report.sentAtDateTime)).format('MM/DD/YYYY')
                    : '-'}
                </Stack>
              ))}
            </Stack>
          ),
          Footer: () => '',
        },
        {
          id: 'reports',
          label: 'Actions',
          Footer: () => '',
          accessor: (row: PaymentForecastsInternalData) =>
            (isLoadingSendOneReport || isLoadingDownloadReport) &&
            currentClubName === row.clubName ? (
              <Stack spacing={1} p={1.5}>
                <CircularProgress size={24} />
              </Stack>
            ) : (
              <Stack>
                {row?.reports?.map((report) => (
                  <Stack
                    direction="row"
                    spacing={1}
                    key={report.uuid}
                    sx={{
                      minHeight: '24px',
                    }}
                  >
                    <IconButton
                      sx={{
                        padding: 0,
                        top: '-4px',
                        cursor: 'pointer',
                        color: 'primary.dark',
                        '&:hover': { color: 'primary.main' },
                      }}
                      onClick={(e) => {
                        setCurrentClubName(row.clubName);
                        e.stopPropagation();
                        callback(report, CallbackType.SEND);
                      }}
                    >
                      <ForwardToInboxIcon sx={{ fontSize: 18 }} />
                    </IconButton>

                    <IconButton
                      sx={{
                        padding: 0,
                        top: '-4px',
                        cursor: 'pointer',
                        color: 'primary.dark',
                        '&:hover': { color: 'primary.main' },
                      }}
                      onClick={(e) => {
                        setCurrentClubName(row.clubName);
                        e.stopPropagation();
                        callback(report, CallbackType.DOWNLOAD);
                      }}
                    >
                      <DownloadIcon sx={{ fontSize: 20 }} />
                    </IconButton>
                  </Stack>
                ))}
              </Stack>
            ),
        },
      ];
    },
    [isLoadingSendOneReport, isLoadingDownloadReport, currentClubName],
  );

  const handleReportAction = useCallback(
    (report: ReportDto, type: CallbackType) => {
      if (report.startPeriod && report.endPeriod && report.entityType) {
        if (type === CallbackType.DOWNLOAD) {
          paymentForecastsDownloadReports(report.uuid)
            .then((res: any) => {
              const fileName = report.s3Path.split('/').pop() ?? '';

              const downloadBody = {
                res,
                reportName: report.name,
                setNotifications,
                fileType: 'application/pdf',
                fileName: fileName,
              };
              downloadReportFile(downloadBody);
            })
            .catch(() => {
              setNotifications({
                name: `Download ${report.name} Report`,
                type: 'error',
                message: `The ${report.name} report has not been downloaded, try again later`,
              });
            });
        }
        if (type === CallbackType.SEND) {
          paymentForecastsSendReports(report.uuid)
            .then(() => {
              setNotifications({
                name: `Send ${report.name} Report`,
                type: 'success',
                message: `The ${report.name} report has been sent successfully`,
              });
              refetch();
            })
            .catch(() => {
              setNotifications({
                name: `Send ${report.name} Report`,
                type: 'error',
                message: `The ${report.name} report has not been sent, try again later`,
              });
            });
        }
      }
    },
    [paymentForecastsSendReports, paymentForecastsDownloadReports, refetch],
  );

  const handleRowClick = useCallback(
    (row: PaymentForecastsInternalData) => {
      navigators.toDetailScreen?.(params.uuid || '', 'club', row.clubUuid.toString(), {
        startPeriod: search.get('startPeriod') ?? '',
        endPeriod: search.get('endPeriod') ?? '',
      });
    },
    [navigators, params.uuid, search],
  );

  return (
    <CRUDPageableLayout>
      {isLoading && <LoaderCard label="Loading Payment Forecasts Detail..." sx={{ p: 4 }} />}
      <NotificationSnackbar
        clearNotification={() => setNotifications(undefined)}
        notification={notifications}
      />
      {!isLoading && (
        <Box component={Paper}>
          <BreadcrumbHeader breadcrumbs={breadcrumbs} />
          <DataTable
            columns={getPaymentRequestTableColumns(handleReportAction)}
            rows={data ?? []}
            onRowClick={handleRowClick}
            stickyHeader
            stickyOffset={152}
          />
        </Box>
      )}
    </CRUDPageableLayout>
  );
};

export default PaymentForecastsDetail;
