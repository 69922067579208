import { BaseAPI } from 'api/auth';
import { PageableResponse } from 'api/interfaces/responses';
import { ENV_BACKOFFICE_BASE_API_URL } from 'lib/envConfig';
import { ServiceProvidedDto } from './interfaces';

export function fetchServicesProvided() {
  return BaseAPI.get<PageableResponse<ServiceProvidedDto>>(
    `${ENV_BACKOFFICE_BASE_API_URL}/v1/services-provided`,
    { params: { size: 9999 } },
  );
}
