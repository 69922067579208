import { BillingRequestDto } from 'api/invoices/interfaces/BillingRequestDto';
import { CRUDPageableProvider, usePageableContextData } from 'components/CRUDPageable';
import { CRUDPageableReactQuery } from 'hooks/useReactQueryHelpers';
import { PropsWithChildren } from 'react';
import { manageBillingRequestApi } from 'api/invoices/manageBillingRequestApi';
import { BillingRequestData } from './types';
import { convertToCurrency } from 'utils/formatter';
import { useQuery } from 'react-query';
import { getBillingRequestSummary } from './helpers/getBillingRequestSummary';
import { getPlaceholderBillRequests } from './helpers/getPlaceholderBillRequests';
import { convertUTCDateToString } from 'utils/convertDate';

const mapDtoToData = (data: BillingRequestDto): BillingRequestData => {
  let billingRequestItemsTotal = 0;
  const lineItems = data.lineItems.map((item, index) => {
    billingRequestItemsTotal += item.totalPrice || 0;
    return {
      uuid: item.uuid,
      order: index + 1,
      description: item.description,
      itemNumber: item.itemNumber,
      quantity: item.quantity,
      unitPrice: convertToCurrency(item.totalPrice / item.quantity || 0),
      totalPrice: convertToCurrency(item.totalPrice || 0),
      type: item.type,
    };
  });

  return {
    uuid: data.uuid,
    number: data.number,
    amount: convertToCurrency(data.totalBillableAmount),
    status: data.status,
    lineItems,
    name: data.name,
    customerNumber: data.customerNumber,
    purchaseOrderNumber: data.purchaseOrderNumber,
    contactPerson: data.contactPerson,
    assignedAccountExecutive: data.assignedAccountExecutive,
    arInvoiceNumber: data.arInvoiceNumber,
    startPeriod: data.startPeriod,
    billingPeriodRange:
      convertUTCDateToString(data.startPeriod) + ' - ' + convertUTCDateToString(data.endPeriod),
    totalDue: convertToCurrency(billingRequestItemsTotal),
    billingLevel: data.billingLevel,
  };
};

export const BillingRequestQuery = CRUDPageableReactQuery<BillingRequestDto, BillingRequestData>(
  'QUERY_BILLING_REQUEST',
  manageBillingRequestApi,
  mapDtoToData,
);

export function useBillingRequestContext() {
  const context = usePageableContextData<BillingRequestDto, BillingRequestData>();

  return {
    ...context,
    sortOptions: {
      ...context.sortOptions,
      ...(context.sortOptions.orderBy ? {} : { orderBy: 'startPeriod', orderType: 'desc' }),
    },
  };
}

export default function BillingRequestProvider({ children }: PropsWithChildren) {
  return (
    <CRUDPageableProvider queries={BillingRequestQuery} reQueryOnMount>
      {children}
    </CRUDPageableProvider>
  );
}

export function useBillingRequestSummary() {
  return useQuery('billRequestInvoiceSummary', getBillingRequestSummary, {
    placeholderData: getPlaceholderBillRequests(),
  });
}
