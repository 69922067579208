import { fetchReports, fetchReportSummary } from 'api/reports';
import { PageableParams, useMappedReactQuery } from 'hooks/useReactQueryHelpers';
import { ReportDto, ReportData, ReportSummaryData, ReportSummaryDto } from './types';

const QUERY_REPORTS = 'QUERY_REPORTS';

function mapReportDtoToData(reportDtos: ReportDto[]): ReportData[] {
  return reportDtos?.map((reportDto) => ({
    s3Path: reportDto.s3Path,
    reportUuid: reportDto.uuid,
    reportName: reportDto.name,
    reportNumber: reportDto.number || '',
    monthYear: reportDto.startPeriod || '',
    clientName: reportDto.clientName,
    billingNumber: reportDto.billingNumber,
    createdAtDateTime: reportDto.createdAtDateTime || '',
  }));
}

function mapReportSummaryDtoToData(reportSummaryDtos: ReportSummaryDto[]): ReportSummaryData[] {
  return reportSummaryDtos.map((reportSummary) => ({
    number: reportSummary.number,
    name: reportSummary.name,
    reports: mapReportDtoToData(reportSummary.reports),
    monthYear: reportSummary.monthYear,
  }));
}

export function useGetReportsByNumber(reportNumber: string, params: PageableParams) {
  return useMappedReactQuery(
    QUERY_REPORTS + reportNumber,
    () => fetchReports(reportNumber, params).then((res) => res.content),
    { mapper: mapReportDtoToData },
    params,
  );
}

export function useGetReportSummary(params: PageableParams) {
  return useMappedReactQuery(
    QUERY_REPORTS,
    () => fetchReportSummary(params).then((res) => res.content),
    { mapper: mapReportSummaryDtoToData },
    params,
  );
}
